import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorComponent } from '@tinymce/tinymce-angular';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { ToasterService } from '../../../infrastructure/services';
import { ContentFileProvider } from '../../../app-admin/providers/content-file.provider';
import { fonts } from '../../consts/fonts.const';
import { transformToCloudfrontUrl } from '../../../infrastructure/helpers/cloudfront.helper';

@Component({
    selector: 'cb-tiny-rich-editor',
    templateUrl: './tiny-rich-editor.component.html',
    styleUrls: ['./tiny-rich-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: TinyRichEditorComponent,
            multi: true
        }
    ]
})
export class TinyRichEditorComponent implements OnInit, ControlValueAccessor, AfterViewInit {
    @Input() placeholder: string;
    @Input() additionalParams: any;
    @ViewChild('tinyEditor', { static: true }) private tinyEditor: EditorComponent;
    @Output() onContentChanged: EventEmitter<any> = new EventEmitter();
    @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter();
    internalModel = '';
    isLoading: boolean;
    tinyEditorBasicSettings = {
        setup: function(editor) {
            editor.on('init', function(e) {
                setTimeout(() => editor.getContainer() ? editor.getContainer()
                                    .querySelector('[aria-label="Insert/edit link"]')
                                    .setAttribute('title', 'Insert Link/File') : null
                , 0);
            });
        },
        plugins: 'image, charmap, code, link, lists, advlist, table',
        suffix: '.min',
        base_url: transformToCloudfrontUrl(this.getTinyAssetsUrl('assets/tinymce')),
        branding: false,
        browser_spellcheck: true,
        font_formats: this.fontList,
        relative_urls: false,
        convert_urls : false,
        content_css: transformToCloudfrontUrl(this.getTinyAssetsUrl('assets/font.css')),
        content_style: 'body { font-family: \'Lato\', sans-serif; }',
        mobile: {
            theme: 'silver',
            menubar: false,
            height: 300,
            max_height: 500,
            max_width: 500,
            min_height: 400,
            statusbar: false,
            toolbar: false,
            plugins: ['autosave', 'lists', 'autolink']
        },
    };
    tinyEditorTakeSurveySettings = {
        toolbar1: 'bold italic underline strikethrough superscript subscript | bullist numlist link | removeformat | charmap code  | forecolor backcolor  | styleselect fontselect fontsizeselect ',
        menubar: false,
        fontsize_formats: '8px 10px 12px 14px 18px 24px 36px'
    };
    tinyEditorAdminSettings = {
        toolbar1: 'bold italic underline strikethrough superscript subscript | bullist numlist indent outdent link | image insertfile table | removeformat | charmap code | forecolor backcolor  | styleselect fontselect fontsizeselect',
        menubar: false,
        fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
        image_class_list: [{title: 'Default', value: 'tiny_image_clearfix'}]
    };
    tinyEditorSettings: any;
    acceptedFileExtensions = '.png, .pdf, .csv, .ppt, .doc, .pptx, .docx, .txt, .gif, .xls, .jpeg, .xlsx, .jpg';
    acceptedImageExtensions = '.png, .gif, .jpeg, .jpg';
    propagateChanges = () => {};
    propagateTouch = () => {};

    constructor(
        private toasterService: ToasterService,
        private contentFileProvider: ContentFileProvider
    ) {}

    get fontList() {
        return fonts.map(f => `${f.name}=${f.fontFamily}`).join(';');
    }

    getTinyAssetsUrl(baseUrl: string) {
        const virtualDirectoryPrefix = (window['__env'] && window['__env'].virtualDirectory) || '';

        if (_.last(virtualDirectoryPrefix) !== '/') {
            return `${virtualDirectoryPrefix}/${baseUrl}`;
        } else {
            return `${virtualDirectoryPrefix}${baseUrl}`;
        }
    }

    getEditorSettings() {
        if (environment.isAdminSite) {
            return _.assign(
                this.tinyEditorBasicSettings,
                this.tinyEditorAdminSettings,
                this.additionalParams,
                {
                    placeholder: this.placeholder,
                    images_upload_handler: this.imagesUploadHandler.bind(this),
                    file_picker_types: 'file image',
                    file_browser_callback_types: 'file image',
                    file_picker_callback: this.fileUploadHandler.bind(this)
                }
            );
        } else {
            return _.assign(
                this.tinyEditorBasicSettings,
                this.tinyEditorTakeSurveySettings,
                this.additionalParams,
                { placeholder: this.placeholder}
            );
        }
    }

    ngOnInit() {
        this.tinyEditorSettings = this.getEditorSettings();
    }

    imagesUploadHandler(blobInfo, success, failure) {
        // from upload tab
        if (this.checkExtension(blobInfo.filename(), this.acceptedImageExtensions)) {
            const fileData = new FormData();
            fileData.append('image', blobInfo.blob(), blobInfo.filename());
            this.contentFileProvider.uploadContentFile(fileData).pipe(first()).subscribe(data => {
                success(data.file_url);
            }, error => failure(error));
        } else {
            failure('Unsupported file type');
        }

    }

    fileUploadHandler(callback, value, meta) {
        // from general tab
        const acceptedExtensions = meta.filetype === 'image' ? this.acceptedImageExtensions : this.acceptedFileExtensions;
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', acceptedExtensions);
        input.onchange = () => {
            const file = input.files[0];
            if (this.checkExtension(file.name, acceptedExtensions)) {
                const fileData = new FormData();
                fileData.append(meta.filetype, file, file.name);
                this.contentFileProvider.uploadContentFile(fileData).pipe(first()).subscribe(data => {
                    callback(data.file_url, {text: file.name});
                }, error => {
                    callback('', {text: ''});
                    this.errorHandler(error);
                });
            } else {
                callback('', {text: ''});
                this.toasterService.showError({message: 'Unsupported file type'}, true);
            }
        };

        input.click();
    }

    checkExtension(filename, extensions) {
        return _.some(extensions.split(', '), ext => _.includes(filename, ext));
    }

    insertMergeCode(mergeCode: string) {
        this.tinyEditor.editor.execCommand('mceInsertContent', false, mergeCode);
    }

    ngAfterViewInit(): void {
        this.tinyEditor.registerOnChange(this.propagateChanges);
        this.tinyEditor.registerOnTouched(this.propagateTouch);
        this.tinyEditor['_element'].setAttribute('aria-label', 'Answer text');
        this.tinyEditor['_element'].setAttribute('aria-hidden', 'false');
    }

    onSelectionChange(evnt) {
        this.onSelectionChanged.emit(evnt);
    }

    onContentChange(evnt) {
        this.onContentChanged.emit(evnt);
    }

    errorHandler(error) {
        this.isLoading = false;
    }

    writeValue(value: any): void {
        this.internalModel = value;
        this.tinyEditor.writeValue(value);
    }

    registerOnChange(fn: any): void {
        this.propagateChanges = fn;
    }

    registerOnTouched(fn: any): void {
        this.propagateTouch = fn;
    }
}
