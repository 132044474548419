export class QuestionItem {
    id: number;
    surveyId: number;
    page_id: number;
    question_text: string;
    subtext: string;
    alias: string;
    item_type: string;
    position: number;
    html_class: string;
    is_required: boolean;
    allowed_file_types: any[];
    require_unique_answers: boolean;
    width: number;
    include_condition: any;
    item_number?: number;
    server_error_messages?: any[];
    enabled?: boolean;
    is_soft_required?: boolean;
    softRequiredEnabled?: boolean;
}
