import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {
    HtmlTags,
    SurveyDisplayType,
    SurveyQuestionType
} from '../../../../infrastructure/consts/surveys.consts';
import {
    Column,
    MatrixItem,
    MatrixPreview,
    Row
} from '../../../models/survey-items/question-items/matrix';
import * as _ from 'lodash';
import { ValidationObject } from '../../../../infrastructure/models/validation-object.model';
import { getEnabledChoices, getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { SinglelinesPreviewItemComponent } from '../singlelines-item-preview/singlelines-item-preview.component';
import { RadioButtonsPreviewItemComponent } from '../radiobuttons-item-preview/radiobuttons-item-preview.component';
import { CheckboxesPreviewItemComponent } from '../checkboxes-item-preview/checkboxes-item-preview.component';
import { DropDownListPreviewItemComponent } from '../dropdownlist-item-preview/dropdownlist-item-preview.component';
import { CustomSourceDropDownListPreviewItemComponent } from '../custom-source-dropdownlist-item-preview/custom-source-dropdownlist-item-preview.component';
import { RatingScalePreviewItemComponent } from '../rating-scale-item-preview/rating-scale-item-preview.component';
import { SliderPreviewItemComponent } from '../slider-item-preview/slider-item-preview.component';
import { of } from 'rxjs';
import { String } from 'typescript-string-operations';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-matrix-item-preview',
    templateUrl: './matrix-item-preview.component.html',
    styleUrls: ['./matrix-item-preview.component.scss']
})
export class MatrixPreviewItemComponent implements AfterViewInit, OnInit {
    @Input() questionItem: MatrixItem;
    @Input() isPrint: boolean;
    @Input() validationMessages: ValidationObject[];
    @Input() validationText;
    @Output() updated = new EventEmitter<any>();

    // references to child previews, they are used for resetting form
    @ViewChildren(SinglelinesPreviewItemComponent) singleLines: QueryList<
        SinglelinesPreviewItemComponent>;
    @ViewChildren(RadioButtonsPreviewItemComponent) radioButtons: QueryList<RadioButtonsPreviewItemComponent>;
    @ViewChildren(CheckboxesPreviewItemComponent) checkboxes: QueryList<CheckboxesPreviewItemComponent>;
    @ViewChildren(DropDownListPreviewItemComponent) dropDowns: QueryList<DropDownListPreviewItemComponent>;
    @ViewChildren(CustomSourceDropDownListPreviewItemComponent) customDropDowns: QueryList<CustomSourceDropDownListPreviewItemComponent>;
    @ViewChildren(RatingScalePreviewItemComponent) ratingScales: QueryList<RatingScalePreviewItemComponent>;
    @ViewChildren(SliderPreviewItemComponent) sliders: QueryList<SliderPreviewItemComponent>;

    matrix: MatrixPreview;
    headers: any[] = [];
    showQuestionTextRow: boolean;
    showChoiceTextRow: boolean;
    hasSumTotalColumn: boolean;

    singleLineText = SurveyQuestionType.SINGLE_LINE_TEXT;
    multiLineText = SurveyQuestionType.MULTI_LINE_TEXT;
    checkBoxes = SurveyQuestionType.CHECKBOXES;
    radiobuttons = SurveyQuestionType.RADIOBUTTONS;
    ratingScale = SurveyQuestionType.RATINGSCALE;
    dropdownList = SurveyQuestionType.DROPDOWNLIST;
    customSourceDropdownList = SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST;
    netPromoteScore = SurveyQuestionType.NET_PROMOTER_SCORE;
    rating = SurveyQuestionType.RATING;
    slider = SurveyQuestionType.SLIDER;
    message = SurveyDisplayType.MESSAGE;
    matrixSumTotal = SurveyQuestionType.SUM_TOTAL;
    subHeadingRowType = 'Subheading';
    otherRowType = 'Other';
    sumTotalError = 'SumTotalError';
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    emptyRootElement = HtmlTags.ROOT_ELEMENT;
    tableHeadIds = {upperTableHeader: '', choiceHeader: '', rowHeader: ''};
    errorTranslationPrefix = 'VALIDATION.';
    questionId = TakeSurveyData.QUESTION_ID;

    constructor(
        private printService: PrintService,
        private elem: ElementRef
        ) { }

    get matrixColumnHasValidationErrors() {
        if (this.questionItem && this.questionItem.server_error_messages) {
            return this.questionItem.server_error_messages.length > 0;
        }
    }

    ngOnInit() {
        this.matrix = this.createMatrix(this.questionItem); // creates matrix object, inside have rows and orders
        this.showQuestionTextRow = false;
        this.showChoiceTextRow = false;
        this.hasSumTotalColumn = false;
        this.tableHeadIds = {
            upperTableHeader: `upper-header-id${this.matrix.id}-`,
            choiceHeader: `choice-header-id${this.matrix.id}-`,
            rowHeader: `row-header-id${this.matrix.id}-`
        };


        if (this.matrix && this.matrix.rows && this.matrix.rows[0]) {
            const columns = this.matrix.rows[0].columns;
            columns.forEach(c => {
                if (c.prototype_item) {
                    const colNames = [];
                    const isRatingScaleItem = c.prototype_item.item_type === SurveyQuestionType.RATINGSCALE;
                    const ratingScaleLabels = [];
                    const ratingScalePointLabels = [];
                    let ratingScaleShowPointLabels = false;

                    if (isRatingScaleItem) {
                        const labelType = c.prototype_item.choice_label_type;

                        c.prototype_item.choices.forEach(choice => {
                            const choicePointsLabel = !choice.is_other
                                ? choice.points + ''
                                : (choice.text || '');
                            const choiceLabel = labelType === 'Text' || choice.is_other
                                ? choice.text || ''
                                : (choice.text && labelType !== 'Rating' ? choice.text : choicePointsLabel);

                            colNames.push(choiceLabel);
                            ratingScalePointLabels.push(choicePointsLabel);

                            if (labelType === 'TextAndRating' &&
                                !choice.is_other &&
                                choiceLabel != choicePointsLabel) {
                                ratingScaleShowPointLabels = true;
                            }
                        });

                        if (ratingScaleShowPointLabels) {
                            for (let i = 0; i < colNames.length; i++) {
                                if (colNames[i] == ratingScalePointLabels[i]) {
                                    colNames[i] = '';
                                }
                            }
                        }

                        if (c.prototype_item.start_text ||
                            c.prototype_item.mid_text ||
                            c.prototype_item.end_text)
                        {
                            ratingScaleLabels.push(c.prototype_item.start_text || '');
                            ratingScaleLabels.push(c.prototype_item.mid_text || '');
                            ratingScaleLabels.push(c.prototype_item.end_text || '');
                        }
                    } else {
                        const column_choices = c.prototype_item.choices &&
                            c.prototype_item.item_type !== this.dropdownList
                            ? getEnabledChoices(c.prototype_item.choices)
                            : [];
                        column_choices.forEach(choice => {
                            colNames.push(choice.text || '');
                        });
                    }

                    if (c.prototype_item.allow_other) {
                        colNames.push('Other');
                    }

                    if (c.prototype_item.question_text) {
                        this.showQuestionTextRow = true;
                    }

                    if (colNames.length) {
                        this.showChoiceTextRow = true;
                    }

                    if (this.isSumTotalColumn(c)) {
                        this.hasSumTotalColumn = true;
                    }

                    this.headers.push({
                        text: c.prototype_item.question_text,
                        width: c.width,
                        colNames,
                        ratingScaleLabels,
                        ratingScalePointLabels,
                        ratingScaleShowPointLabels,
                        enable_not_applicable:
                            c.prototype_item.enable_not_applicable ||
                            c.prototype_item.allow_none_of_above,
                        itemType: c.prototype_item.item_type
                    });
                } else {
                    this.headers.push({
                        text: '',
                        width: c.width,
                        colNames: [],
                        ratingScaleLabels: [],
                        ratingScalePointLabels: [],
                        ratingScaleShowPointLabels: false,
                        enable_not_applicable: false,
                        itemType: undefined
                    });
                }
            });
        }
        // Create two-dimensional array of answers
        this.questionItem.matrixValueAnswers = [];
        this.initMatrixValueAnswers();
    }

    ngAfterViewInit(): void {
        this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
    }

    public resetForm() {
        this.singleLines.forEach(line => line.resetForm());
        this.radioButtons.forEach(radioButton => radioButton.resetForm());
        this.checkboxes.forEach(checkbox => checkbox.resetForm());
        this.dropDowns.forEach(dropDown => dropDown.resetForm());
        this.customDropDowns.forEach(customDropDowns => customDropDowns.resetForm());
        this.ratingScales.forEach(ratingScale => ratingScale.resetForm());
        this.sliders.forEach(slider => slider.resetForm());
    }

    private initMatrixValueAnswers() {
        const elements = this.questionItem.elements;
        // some sumtotal stuff
        const sumTotalColumns = this.questionItem.columns.filter(
            x =>
                x.prototype_item &&
                x.prototype_item.item_type === this.matrixSumTotal
        );
        sumTotalColumns.forEach(col => {
            elements
                .filter(ans => ans.column === col.position)
                .forEach(s => {
                    s.item.operator = col.prototype_item.operator;
                    s.item.total_value = col.prototype_item.total_value;
                    s.item.min_value = col.prototype_item.min_value;
                    s.item.max_value = col.prototype_item.max_value;
                    s.item.columnId = col.id;
                });
        });
        this.initTwoDimensionalArray(elements);
    }

    private initTwoDimensionalArray(elements) {
        const subHeadingRowsPositions = this.questionItem.rows
            .filter(x => x.row_type === this.subHeadingRowType)
            .map(x => x.position);
        const otherRowsPositions = this.questionItem.rows
            .filter(x => x.row_type === this.otherRowType)
            .map(x => x.position);
        const rowsNumber = this.questionItem.rows.length;
        const columnsNumber = this.questionItem.columns.length;
        for (let i = 0; i < rowsNumber; i++) {
            this.questionItem.matrixValueAnswers[i] = [];
            for (let j = 0; j < columnsNumber; j++) {
                const cell = elements.find(
                    x => x.row === i + 1 && x.column === j + 1
                );
                // Missed elements are possible in Checkbox 6 for subheading row
                this.questionItem.matrixValueAnswers[i][j] = {
                    cell_id: cell ? cell.item.id : null,
                    prototype_item_type: cell ? cell.item.prototype_item_type : null,
                    answer: {},
                    column: j + 1,
                    rowPosition: i + 1,
                    isSubheading: !!subHeadingRowsPositions.includes(i + 1)
                };
                if (cell && cell.item.prototype_item_type === this.matrixSumTotal) {
                    this.questionItem.matrixValueAnswers[i][j].answer.operator =
                        cell.item.operator;
                    this.questionItem.matrixValueAnswers[i][
                        j
                    ].answer.total_value = cell.item.total_value;
                    this.questionItem.matrixValueAnswers[i][
                        j
                    ].answer.min_value = cell.item.min_value;
                    this.questionItem.matrixValueAnswers[i][
                        j
                    ].answer.max_value = cell.item.max_value;
                    this.questionItem.matrixValueAnswers[i][j].answer.columnId =
                        cell.item.columnId;
                }

                if (cell) {
                    this.addAnswerPropertiesDependingOnItemType(
                        this.questionItem.matrixValueAnswers[i][j]
                    );
                }
            }
        }

        // Adding cells to matrix if fow type is 'Other'
        if (otherRowsPositions && otherRowsPositions.length) {
            otherRowsPositions.forEach(r => {
                const otherValue = this.questionItem.elements.find(
                    x => x.row === r && x.item.item_type === this.singleLineText
                );
                if (otherValue) {
                    const answer = _.chain(this.questionItem).get('subitems').find({ item_id: otherValue.item.id }).get('answer.text').value();
                    const row = _.find(this.matrix.rows, { position: r });
                    const defaultText = row && row['text'];
                    if (row && answer) {
                        row['text'] = answer || defaultText;
                    }
                    this.questionItem.matrixValueAnswers[r - 1].push({
                        cell_id: otherValue.item.id,
                        prototype_item_type: this.singleLineText,
                        isOtherRow: true,
                        answer: {
                            answer_text: answer || defaultText
                        }
                    });
                }
            });
        }
    }

    public rowTextChanged($event, row) {
        const lastCellIndex = this.questionItem.matrixValueAnswers[
            row.position - 1
        ].length;
        this.questionItem.matrixValueAnswers[row.position - 1][
            lastCellIndex - 1
            ].answer.answer_text = $event.target.value;
    }

    findAnswerValue(item_id) {
        return _.chain(this.questionItem)
            .get('subitems')
            .find({ item_id })
            .get('answer')
            .value();
    }

    private addAnswerPropertiesDependingOnItemType(cell) {
        const answer = this.findAnswerValue(cell.cell_id);
        const column = _.find(this.questionItem.columns, {
            position: cell.column
        });
        const item = _.cloneDeep(column.prototype_item);

        if (item && item.choices) {
            item.choices = getEnabledChoices(item.choices);
        }

        switch (cell.prototype_item_type) {
            case SurveyQuestionType.SUM_TOTAL:
                if (answer && answer.text) {
                    item.answer_text = cell.answer.answer_text = answer.text;
                }
                break;
            case SurveyQuestionType.SINGLE_LINE_TEXT:
                if (answer && answer.text) {
                    item.default_text = cell.answer.answer_text = answer.text;
                }
                break;
            case SurveyQuestionType.RADIOBUTTONS:
            case SurveyQuestionType.DROPDOWNLIST:
                if (answer && answer.choice_id) {
                    item.choices = _.map(item.choices, choice => {
                        choice.is_default = choice.id === answer.choice_id;
                        return choice;
                    });
                }
                break;
            case SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST:
                if (answer && answer.value) {
                    item.answer = answer;
                }
                break;
            case SurveyQuestionType.CHECKBOXES:
                if (answer && answer.choices) {
                    const choiceIds = _.map(answer.choices, 'choice_id');
                    item.choices = _.map(item.choices, choice => {
                        choice.is_default = choiceIds.indexOf(choice.id) > -1;
                        return choice;
                    });
                }
                break;
            case SurveyQuestionType.RATINGSCALE:
                if (answer && answer.choice_id) {
                    item.choice_id = answer.choice_id;
                }
                break;
            case SurveyQuestionType.SLIDER:
                if (answer && answer.value) {
                    item.value = cell.answer.value = answer.value;
                } else if (answer && answer.choice_id) {
                    const optionIndex = _.findIndex(item.choices, {
                        id: answer.choice_id
                    });
                    item.value = cell.answer.value = optionIndex + 1;
                }
                break;
            default:
                break;
        }
        cell.item = item;
    }

    public getColumnItem(item, rowPosition, columnPosition) {
        const cell =
            this.questionItem.matrixValueAnswers &&
            this.getMatrixCell(rowPosition, columnPosition);
        return (cell && cell.item) || item;
    }

    private getMatrixCell(rowPosition, columnPosition) {
        return this.questionItem.matrixValueAnswers[rowPosition - 1][
            columnPosition - 1
        ];
    }

    public updateMatrixValueAnswers(newValue, rowPosition, columnPosition) {
        this.getMatrixCell(rowPosition, columnPosition).answer = {
            ...this.getMatrixCell(rowPosition, columnPosition).answer,
            ...newValue
        };

        this.updated.emit(this.getMatrixCell(rowPosition, columnPosition));
    }

    public getMinMaxErrorMessage(rowPosition, columnPosition) {
        if (this.validationMessages) {
            const cell = this.validationMessages.find(
                x =>
                    x.item_id ===
                    this.getMatrixCell(rowPosition, columnPosition).cell_id
            );
            return cell ? cell.validationMessage : null;
        }
    }

    public isSumTotalColumn(column: Column) {
        return (
            column.prototype_item &&
            column.prototype_item.item_type === this.matrixSumTotal
        );
    }

    public getTotalForSumTotalColumn(column: Column) {
        if (!this.questionItem.matrixValueAnswers) {
            return 0;
        }

        let total = 0;
        this.matrix.rows.forEach(row => {
            const answer = this.getMatrixCell(row.position, column.position)
                .answer;
            if (answer && answer.answer_text) {
                total += +answer.answer_text.replace(',', '.');
            }
        });

        return Math.round(total * 100) / 100 ;
    }

    public getMatrixColumnValidationMessages(columnId) {
        const errors = [];

        const columnTotalValidationError = this.validationMessages
            ? this.validationMessages
                  .filter(x => x.type === this.sumTotalError)
                  .find(x => x.item_id === columnId)
            : null;

        if (
            columnTotalValidationError &&
            columnTotalValidationError.validationMessage
        ) {
            errors.push(columnTotalValidationError.validationMessage);
        }

        if (this.questionItem && this.questionItem.server_error_messages) {
            const column = this.questionItem.server_error_messages.find(
                x => x.columnId === columnId
            );
            if (column) {
                errors.push(column.errorText);
            }
        }
        return errors;
    }

    public getMatrixSubItemErrorMessages(rowPosition, columnPosition) {
        let errors;
        const cellId = this.getMatrixCell(rowPosition, columnPosition).cell_id;
        if (this.questionItem && this.questionItem.server_error_messages) {
            errors = this.questionItem.server_error_messages
                .filter(err => err.subItemId === cellId)
                .map(err => of(err.errorText));
        } else if (this.validationMessages && this.validationMessages.length) {
            errors = this.validationMessages
                .filter(item => item.item_id === cellId)
                .map(item =>  {
                    return  item.params ?  String.Format(this.validationText[item.validationMessage], item.params)
                                            : this.validationText[item.validationMessage];
                });
        }
        return errors;
    }

    private createMatrix(item: MatrixItem): MatrixPreview {
        const matrix = new MatrixPreview();
        let rowIndex = 1;
        let row = new Row();

        if (this.questionItem && this.questionItem.elements) {
            this.questionItem.elements.forEach(e => {
                if (e.row !== rowIndex) {
                    if (row) {
                        matrix.rows.push(row);
                        rowIndex++;
                    }
                }
                if (e.row === rowIndex && e.column === 1) {
                    row = this.createRow(e);
                }
                const column = this.createColumn(e);

                if (row) {
                    row.columns.push(column);
                }
            });
            if (row) {
                matrix.rows.push(row);
            }

            matrix.id = this.questionItem.id;
            matrix.text = this.questionItem.question_text;
            matrix.subtext = this.questionItem.subtext;
        }
        return matrix;
    }

    private createColumn(element: any): Column {
        let column = new Column();
        if (!this.questionItem || !this.questionItem.columns || !element) {
            return column;
        }
        column = this.questionItem.columns.find(
            c => c.position === element.column
        );

        if (column) {
            column.prototype_item_type = element.prototype_item_type;
            return column;
        }
        return column;
    }

    private createRow(element: any): Row {
        const row = new Row();
        row.columns = [];

        if (!this.questionItem || !this.questionItem.rows || !element) {
            return row;
        }

        const existedRow = this.questionItem.rows.find(
            c => c.position === element.row
        );
        if (existedRow) {
            row.id = existedRow.id;
            row.text = existedRow.text;
            row.alias = existedRow.alias;
            row.row_type = existedRow.row_type;
            row.position = existedRow.position;
        }

        if (!existedRow) {
            return null;
        }
        return row;
    }

    checkOnExcluded(id, i) {
        return (
            (!this.questionItem.excluded_rows ||
                !this.questionItem.excluded_rows.includes(id)) &&
            i >= 0
        );
    }

    checkSumTotalPos(columns) {
        if (this.isSumTotalColumn(columns[0])) {
            return columns;
        } else {
            return columns.filter((item, i) => i !== 0);
        }
    }

    checkOnRequired() {
        const currentErrors = this.validationMessages.filter((item) => {
            if (item.type === 'RequiredError') {
                return this.questionItem['subitems'].find(x => x.item_id === item.item_id);
            }
        });
        return currentErrors.length;
    }

    getMatrixRowClass() {
        const align = this.questionItem.row_text_align ? this.questionItem.row_text_align.toLowerCase() : 'center';
        return `matrix-row-text-${align}`;
    }

    calcWidth(header) {
        if (header.itemType !== this.slider) {
            return 100 / header.colNames.length + '%';
        } else {
            return 'auto';
        }
    }

    getRatingScaleLabelsWidth(header) {
        const cols = header.colNames.length;
        return header.enable_not_applicable ? `calc(100%/${cols}*${cols - 1})` : 'inherit';
    }

    getRatingScaleLabelWidth(header, index) {
        const cols = header.colNames.length;
        if (
            index === 1
        ) {
            return header.enable_not_applicable ? `calc(100%/${cols - 1}*${cols - 3})` : `calc(100%/${cols}*${cols - 2})`;
        } else {
            return header.enable_not_applicable ? `calc(100%/${cols - 1})` : `calc(100%/${cols})`;
        }
    }

}
