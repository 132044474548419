import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { SurveyQuestionType } from '../../../../infrastructure/consts/surveys.consts';
import { ContactFormField, ContactFormItem, ContactFormResponseSubitem } from '../../../../shared/models/survey-items/question-items/contactFormItem';
import * as _ from 'lodash';
import { ValidationObject } from '../../../../infrastructure/models/validation-object.model';
import { getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { SinglelinesPreviewItemComponent } from '../singlelines-item-preview/singlelines-item-preview.component';
import { of, Subscription } from 'rxjs';
import { String } from 'typescript-string-operations';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';
import { SurveysSettingProvider } from '../../../../infrastructure/providers/surveys-setting.provider';

@Component({
    selector: 'cb-contact-form-item-preview',
    templateUrl: './contact-form-item-preview.component.html',
    styleUrls: ['./contact-form-item-preview.component.scss']
})
export class ContactFormPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: ContactFormItem;
    @Input() isPrint: boolean;
    @Input() validationMessages: ValidationObject[];
    @Input() validationText;
    @Output() updated = new EventEmitter<any>();

    @ViewChildren(SinglelinesPreviewItemComponent) singleLines: QueryList<SinglelinesPreviewItemComponent>;

    enabledFormFields: ContactFormField[] = [];
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    tableRowHeaderPrefix = ''
    questionId = TakeSurveyData.QUESTION_ID;
    appearanceSettings;
    settingsSubscription: Subscription;

    constructor(
        private printService: PrintService,
        private surveysSettingProvider: SurveysSettingProvider,
        private elem: ElementRef
        ) { }

    ngOnInit() {
        this.settingsSubscription = this.surveysSettingProvider.storedAppearanceSettings
            .subscribe(data => { this.appearanceSettings = data; });

        this.enabledFormFields = this.questionItem.fields
            .filter(f => f.enabled)
            .sort(function(a, b) { return a.position - b.position; });

        this.questionItem.is_required = false; // hide asterisks for required parent question

        this.tableRowHeaderPrefix = `row-header-id${this.questionItem.id}-`;

        for (let i = 0; i < this.enabledFormFields.length; i++) {
            const formField = this.enabledFormFields[i];
            this.initFormFieldAnswer(formField);
        }
    }

    ngAfterViewInit(): void {
        this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
    }

    public resetForm() {
        this.singleLines.forEach(line => line.resetForm());
    }

    private initFormFieldAnswer(field: ContactFormField) {
        const responseSubitem = this.getResponseSubitemForFormField(field);
        const answer = responseSubitem != null ? responseSubitem.answer : null;

        switch (field.question.item_type) {
            case SurveyQuestionType.SINGLE_LINE_TEXT:
                field.question.answer = {
                    answer_text: answer
                        ? answer.text || ''
                        : field.question.default_text || ''
                };
                field.question.default_text = field.question.answer.answer_text;
                break;
            default:
                break;
        }
    }

    private getResponseSubitemForFormField(field: ContactFormField): ContactFormResponseSubitem {
        return this.questionItem.subitems
            ? this.questionItem.subitems.find(x => x.item_id === field.question.id)
            : null;
    }

    public updateSubquestionAnswer(formField: ContactFormField, newValue) {
        formField.question.answer = {
            ...formField.question.answer,
            ...newValue
        };

        this.updated.emit(formField.question);
    }

    getSubquestionErrorMessages(field: ContactFormField) {
        let errors;
        const subquestionId = field.question.id;
        if (this.questionItem && this.questionItem.server_error_messages) {
            errors = this.questionItem.server_error_messages
                .filter(err => err.subItemId === subquestionId)
                .map(err => of(err.errorText));
        } else if (this.validationMessages && this.validationMessages.length) {
            errors = this.validationMessages
                .filter(item => item.item_id === subquestionId)
                .map(item =>  {
                    return  item.params
                        ? String.Format(this.validationText[item.validationMessage], item.params)
                        : this.validationText[item.validationMessage];
                });
        }
        return errors;
    }

    isSubquestionExcluded(field: ContactFormField): boolean {
        const responseSubitem = this.getResponseSubitemForFormField(field);
        return responseSubitem != null && responseSubitem.excluded;
    }

    hasServerOrRequiredValidationError(): boolean {
        if (this.questionItem.server_error_messages &&
            this.questionItem.server_error_messages.length > 0) {
            return true;
        }

        if (!this.validationMessages) {
            return false;
        }

        const currentErrors = this.validationMessages.filter((item) => {
            if (item.type === 'RequiredError') {
                return this.questionItem.subitems.find(x => x.item_id === item.item_id);
            }
        });
        return currentErrors.length > 0;
    }

    ngOnDestroy() {
        if (this.settingsSubscription) {
            this.settingsSubscription.unsubscribe();
        }
    }
}
