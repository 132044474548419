<!--mat-dialog-content-->
<div class="dialog-content chargebee-dialog">
    <h2 mat-dialog-title class="chargebee-dialog-title">Information</h2> <br />
    To upgrade, please contact
    <a href="mailto:sales@checkbox.com">sales@checkbox.com</a>. <br />
    We will get your account set-up as soon as possible.

    <div class="buttons-container large-button-container chargebee-dialog">
            <button
                mat-raised-button
                type="button"
                class="btn-submit"
                mat-dialog-close
            >
                {{ 'SHARED.CLOSE' | translate }}
            </button>

    </div>
</div>
