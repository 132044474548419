import { DropdownListItem } from '../../../models/survey-items/question-items/dropdownListItem';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ItemOption } from '../../../models/survey-items/question-items/itemOption';
import { getEnabledChoices, getEnabledRegularChoices, getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../../infrastructure/services/shared.service';
import { Subscription, Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-dropdownlist-item-preview',
    templateUrl: './dropdownlist-item-preview.component.html',
    styleUrls: ['./dropdownlist-item-preview.component.scss']
})
@AutoUnsubscribe()
export class DropDownListPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: DropdownListItem;
    @Input() isMatrix: boolean;
    @Input() isPrint: boolean;
    @Output() updated = new EventEmitter<any>();
    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    questionId = TakeSurveyData.QUESTION_ID;
    enabled_regular_choices: ItemOption[];

    form: FormGroup;
    otherChoice: ItemOption = {};
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    private componentDestroyed = new Subject();

    constructor(
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private printService: PrintService,
        private elem: ElementRef
        ) {}

    ngOnInit() {
        this.createFormGroup();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => this.updated.emit(s));
        this.updated.emit(this.form.value);

        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    ngAfterViewInit(): void {
        if (!this.isMatrix) {
            this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
        }
    }

    private createFormGroup(): void {
        let selectedChoiceId;

        // TODO: randomize only on preview
        if (this.questionItem.randomize) {
            this.questionItem.choices = _.shuffle(this.questionItem.choices);
        }

        const enabled_choices = getEnabledChoices(this.questionItem.choices);
        this.enabled_regular_choices = getEnabledRegularChoices(enabled_choices);

        if (this.questionItem.value) {
            selectedChoiceId = this.questionItem.value;
        } else {
            const selectedChoice = _.find(enabled_choices, { is_default: true });
            selectedChoiceId = selectedChoice ? selectedChoice.id : '';
        }

        if (this.questionItem.allow_other) {
            this.otherChoice = _.find(enabled_choices, { is_other: true }) || {};
        }

        this.form = new FormGroup({
            id: new FormControl(this.questionItem.id),
            value: new FormControl(selectedChoiceId),
            other_value: new FormControl(this.questionItem.other_value)
        });
    }

    resetForm() {
        this.form.get('value').reset();
        this.form.get('other_value').reset();
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }
}
