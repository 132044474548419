import * as survey from '../actions/surveys.actions';
import { State, initialState } from './surveys.state';

export function surveysReducer(
    state = initialState,
    action: survey.Actions
): State {
    switch (action.type) {
        case survey.ADD_SURVEY:
        case survey.GET_SURVEYS_LIST: {
            return {
                isLoading: true,
                addedSurvey: null,
                selectedSurvey: null,
                pagingInfo: state.pagingInfo,
                surveys: state.surveys
            };
        }
        case survey.ADD_SURVEY_SUCCESS: {
            return {
                isLoading: false,
                addedSurvey: action.payload,
                selectedSurvey: state.selectedSurvey,
                pagingInfo: state.pagingInfo,
                surveys: state.surveys
            };
        }
        case survey.GET_SURVEYS_LIST_SUCCESS: {
            const data = action.payload;
            return {
                isLoading: false,
                addedSurvey: state.addedSurvey,
                selectedSurvey: state.selectedSurvey,
                pagingInfo: data.paging_info,
                surveys: data.items
            };
        }
        case survey.SELECT_SURVEY: {
            return {
                isLoading: false,
                addedSurvey: null,
                selectedSurvey: action.payload,
                pagingInfo: state.pagingInfo,
                surveys: state.surveys
            };
        }
        case survey.SURVEY_FAILED: {
            return {
                isLoading: false,
                addedSurvey: null,
                selectedSurvey: null,
                pagingInfo: state.pagingInfo,
                surveys: state.surveys
            };
        }
        default:
            return state;
    }
}
