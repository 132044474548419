import { QuestionItem } from './questionItem';

export class SingleLineItem extends QuestionItem {
    answer_format: string;
    max_length: number;
    default_text: string;
    alias: string;
    min_value: any;
    max_value: any;
    autocomplete_list_id: number;
    autocomplete_list: string;
    autocomplete_list_name: string;
    autocomplete_list_is_default: boolean;
    item_position: string;
    question_text_position: string;
    width: number;
    answer_text?: string;
    regex_validation_message?: string;
    regex_pattern?: string;
}

export const SINGLE_LINE_TYPES = {
    None: 'NONE',
    Date_USA: 'DATE-FORMAT1',
    Date_ROTW: 'DATE-FORMAT2',
    AlphaNumeric: 'ALPHA-NUMERIC',
    Alpha: 'LETTERS',
    Decimal: 'DECIMAL',
    Email: 'EMAIL',
    Integer: 'INTEGER',
    Money: 'MONEY',
    Numeric: 'NUMERIC',
    Postal: 'POSTAL',
    Phone: 'PHONE',
    SSN: 'SOCIAL',
    Uppercase: 'STRING-UPPERCASE',
    Lowercase: 'STRING-LOWERCASE',
    URL: 'URL',
    Regex: 'REGEX',
}
