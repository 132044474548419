import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, skip } from 'rxjs/operators';
import { LayoutActions } from '../../infrastructure/consts/layout-actions.const';
import { ApplicationError, ClientUnexpectedError } from '../../infrastructure/models';
import { Contact } from '../../infrastructure/models/contact.model';
import { ErrorStore, IdentityStore, LayoutStore } from '../../infrastructure/store';
import { ActiveDirectoryService, PrintMode, PrintService, SharedService, ToasterService } from '../../infrastructure/services';
import { SurveysActions } from '../../infrastructure/consts/surveys.consts';
import { UserRoles } from '../../infrastructure/consts/auth.consts';
import { ErrorMessage } from '../../infrastructure/consts/error.consts';
import { TranslateService } from '@ngx-translate/core';
import { NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import * as _ from 'lodash';
import { ZendeskSsoProvider } from '../providers';
import { environment } from '../../environments/environment';
import { version } from '../../../package.json';
import { ApplicationLanguageService, IdentityProvider, JWTProvider, StorageProvider } from '../../infrastructure/providers';
import { BrowserDetectionService } from '../shared/browserDetection.service';
import { MyAccountInfoProvider } from '../providers/support/my-account-info-provider';
import { AccountInfo } from '../models/support/account-info-model';
import { EnvironmentProvider } from '../../infrastructure/providers/environment.provider';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmailVerificationDialogComponent } from '../identity/email-verification-dialog/email-verification-dialog.component';
import { EmailVerificationProvider } from '../providers/support/email-verification.provider';
import { ContactEmailVerificationCase, ContactEmailVerificationCodeStatus, ContactEmailVerificationStatus } from '../models/support/email-verification.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ChargebeeDialogComponent } from '../chargebee-dialog/chargebee-dialog.component';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @Input() overlapTrigger: boolean;

    public SIGNOUT_ACTION: string = LayoutActions.SIGNOUT;
    public SUPPORT_ACTION: string = LayoutActions.SUPPORT;
    public SETTINGS_ACTION: string = LayoutActions.SETTINGS;
    public DOWNLOADS: string = LayoutActions.DOWNLOADS;
    public EDIT_MY_INFO: string = LayoutActions.EDIT_MY_INFO;
    public SYSTEM_ACTION: string = LayoutActions.SYSTEM;
    public sidenavOpened: Observable<boolean>;
    public user: Observable<Contact>;
    public userName: string;
    private lastError: Observable<ApplicationError>;
    public langs: string[] = ['en', 'fr', 'ru'];
    public defaultLang = 'en';
    public isMenuOpened = 'menu-closed';
    public isMultiTenant;
    public isPrintSurvey: boolean;
    public isPrintReport: boolean;
    public url: string;
    public isLoading: boolean;
    public isChangingRoute: boolean;
    public currentUserRoles: string[];
    public defaultUrl: string;
    public UPGRADE_ACTION: string = LayoutActions.UPGRADE;
    public accountInfo: AccountInfo;
    public isUpgradeable = false;
    public emailVerificationDialogRef: MatDialogRef<EmailVerificationDialogComponent>;
    jwtInProgress: boolean;


    public get userRoles() {
        return UserRoles;
    }

    public _printMode = new Subject<boolean>();
    public printMode: boolean;

    constructor(
        private translateService: TranslateService,
        private layoutStore: LayoutStore,
        private identityStore: IdentityStore,
        private errorStore: ErrorStore,
        private zendeskSsoProvider: ZendeskSsoProvider,
        private router: Router,
        private sharedService: SharedService,
        private toasterService: ToasterService,
        private cdr: ChangeDetectorRef,
        private activeDirectoryService: ActiveDirectoryService,
        private browserDetectionService: BrowserDetectionService,
        private storageProvider: StorageProvider,
        private printService: PrintService,
        private accountInfoProvider: MyAccountInfoProvider,
        private environmentProvider: EnvironmentProvider,
        private applicationLanguageService: ApplicationLanguageService,
        private jwtProvider: JWTProvider,
        private emailVerificationProvider: EmailVerificationProvider,
        private dialog: MatDialog,
        private gtmService: GoogleTagManagerService,
    ) {
        this.lastError = this.errorStore.lastError;
        let routeLoaded = false;
        let shouldWaitForJWT = false;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.url = event.url;
                if (!routeLoaded) {
                    shouldWaitForJWT = this.url.indexOf('?jwt=') > -1;
                    this.jwtInProgress = shouldWaitForJWT;
                    this.initMainPageData(shouldWaitForJWT);
                    routeLoaded = true;
                }
            }
            if (event instanceof RouteConfigLoadStart) {
                this.isChangingRoute = true;
            }
            if (event instanceof RouteConfigLoadEnd) {
                this.isChangingRoute = false;
            }
        });
    }

    get isPrintResponsePage() {
        return this.url && this.url.indexOf('/surveys/print-response') === 0;
    }

    get isPreviewPage() {
        return this.url && this.url.indexOf('/preview/') > -1;
    }

    ngOnInit() {
        this.printVersionToConsole();
        this.isMultiTenant = environment.isMultiTenant;
        this.browserDetectionService.handleBrowserCheck();
        this.initPermanentSubscriptions();
        this.addGoogleTag();
    }

    addGoogleTag() {
        if (environment.containerId) {
            this.gtmService.addGtmToDom();
        }
    }

    initPermanentSubscriptions() {
        this.jwtProvider.jwtLoginInProgress.subscribe(status => {
            this.jwtInProgress = status;
        });
        this.sharedService.getData(SurveysActions.PRINT).subscribe(result => {
            this._printMode.next(result.data);
        });
        this.printService.printMode.subscribe(printMode => {
            this.isPrintReport = printMode == PrintMode.REPORT;
            this._printMode.next(this.isPrintReport);
            this.cdr.detectChanges();
        });

        this._printMode.pipe(distinctUntilChanged(), debounceTime(0)).subscribe(pp => {
            this.printMode = pp;
        });

        this.lastError.subscribe(err => {
            this.handleLastError(err);
        });
    }

    private printVersionToConsole(): void {
        console.log(`Checkbox Version ${version}`);
    }

    initLanguage() {
        return this.applicationLanguageService.initLanguageSettings();
    }

    initUserInfo() {
        return this.storageProvider.getIdentity()
            .pipe(map(_identity => {
                const identity = JSON.parse(_identity);
                this.userName = identity.user_name;
                this.defaultUrl = IdentityProvider.getDefaultUrl(
                    identity,
                    false
                );
                this.currentUserRoles = _.chain(identity)
                    .get('roles')
                    .split(',')
                    .map(role => _.trim(role))
                    .value();
            }));
    }

    initMainPageUserRelatedData() {
        this.isLoading = true;
        this.activeDirectoryService.checkActiveDirectoryAccess();
        this.activeDirectoryService.getSetting();
        forkJoin([
            this.initUserInfo(),
            this.initLanguage()
        ])
            .pipe(first())
            .subscribe(() => {
                this.checkUpgradeStatus();
                this.isLoading = false;
                this.jwtInProgress = false;
            }, err => {
                this.translateService.use(this.defaultLang);
                this.isLoading = false;
                this.jwtInProgress = false;
            });
    }

    initMainPageDefaultData() {
        this.userName = '';
        this.currentUserRoles = [];
        this.initLanguage();
        this.jwtInProgress = false;
    }

    private initMainPageData(skipFirst: boolean): void {
        this.identityStore.isLoggedIn()
            .pipe(skip(+skipFirst))
            .subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    this.initMainPageUserRelatedData();
                } else {
                    this.initMainPageDefaultData();
                }
            });
    }

    private handleLastError(error) {
        if (error instanceof ClientUnexpectedError) {
            console.error(error);
            const message = ErrorMessage.CLIENT_ERROR_MESSAGE;
            this.toasterService.showError({ message, fullError: error.stack_trace });
        }
    }

    public clickOnToolbar(action) {
        if (action === LayoutActions.OPEN_CLOSE_SIDENAV) {
            this.layoutStore.doOpenCloseSidenav();
        }
    }

    public clickOnMenuItem(action) {
        switch (action) {
            case LayoutActions.SIGNOUT:
                this.identityStore.doSignOut();
                break;
            case LayoutActions.SUPPORT:
                this.handleSupportClick();
                break;
            case LayoutActions.SETTINGS:
                this.router.navigate(['/settings/system-preferences']);
                break;
            case LayoutActions.SYSTEM:
                this.router.navigate(['/system/licensing']);
                break;
            case LayoutActions.DOWNLOADS:
                this.router.navigate(['/downloads']);
                break;
            case LayoutActions.EDIT_MY_INFO:
                this.router.navigate(['/edit-my-info']);
                break;
            case LayoutActions.UPGRADE:
                    let domain = this.getDomain(this.environmentProvider.apiUrl)
                    const dialogRef = this.dialog.open(ChargebeeDialogComponent, {
                        data:
                        {
                            width: '1000px',
                            height: '1000px',
                            applicationContextGuid: this.accountInfo.external_id,
                            dialog: this.dialog,
                            domain: domain
                        },
                    });
                break;
        }
    }


    public helpGuides() {
        window.open(
            'https://www.checkbox.com/support/checkbox-7-help-guides/',
            '_blank'
        );
    }

    appMenuOpen() {
        this.isMenuOpened = 'menu-opened';
    }

    appMenuClose() {
        this.isMenuOpened = 'menu-closed';
    }

    isMainContactMenu(): boolean {
        return this.url === '/contacts' || this.url === '/groups';
    }

    hasRole(...roles: string[]): boolean {
        if (this.currentUserRoles && this.currentUserRoles.length) {
            const foundRole = _.find(this.currentUserRoles, r => {
                return roles.includes(r);
            });
            if (foundRole) {
                return true;
            }
        }
        return false;
    }

    private checkUpgradeStatus() {
        if (this.isMultiTenant && this.currentUserRoles.includes('System Administrator')) {
            this.accountInfoProvider
                .getAccountInfo()
                .pipe(first())
                .subscribe((accountInfo: AccountInfo) => {
                    this.accountInfo = accountInfo;
                    const date = new Date();
                    date.setDate(date.getDate() + 100);
                    const cookie = `TrialUser=${ this.accountInfo.is_trial ? 1 : 0}; path=/; expires=${date.toUTCString()}; SameSite=None; Secure`;
                    document.cookie = cookie;
                    if(environment.containerId){
                        this.gtmService.pushTag({ TrialUser: `${this.accountInfo.is_trial ? 1 : 0}`})
                    }
                    if (this.accountInfo.is_trial) {
                        this.isUpgradeable = true;
                    }
                });
        }
    }

    getDomain(url) {
        const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
        const hostName = match[2];
        let domain = hostName;

        if (hostName != null) {
            const parts = hostName.split('.').reverse();

            if (parts != null && parts.length > 1) {
                domain = parts[1] + '.' + parts[0];

                if (hostName.toLowerCase().indexOf('.co.uk') !== -1 && parts.length > 2) {
                    domain = parts[2] + '.' + domain;
                }
            }
        }

        return domain;
    }

    goToZendesk() {
        this.zendeskSsoProvider.getJwt().subscribe(res => {
            window.open(
                'https://checkbox.zendesk.com/access/jwt?jwt=' +
                res.token, '_blank');
        });
    }

    showEmailVerificationDialog() {
        this.emailVerificationDialogRef = this.dialog.open(
            EmailVerificationDialogComponent,
            {
                width: '536px',
                data: {
                    contactId: this.userName,
                    verificationCase: ContactEmailVerificationCase.SupportEmailVerification
                }
            }
        );

        this.emailVerificationDialogRef.afterClosed().subscribe(data => {
            if (data && data.status == ContactEmailVerificationCodeStatus.Valid) {
                this.goToZendesk();
            }
        });
    }

    handleSupportClick() {
        this.emailVerificationProvider.getEmailVerificationStatus(this.userName)
            .pipe(first())
            .subscribe((status) =>{
                switch(status){
                    case ContactEmailVerificationStatus.Verified:
                        this.goToZendesk();
                        break;
                    case ContactEmailVerificationStatus.NotVerified:
                        this.showEmailVerificationDialog();
                        break;
                }
            });
    }
}
