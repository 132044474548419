import { PagingInfo, EmptyPagingInfo } from '../../../infrastructure/models';
import { Survey } from '../../models';

export interface State {
    isLoading: boolean;
    addedSurvey: Survey;
    selectedSurvey: Survey;
    pagingInfo: PagingInfo;
    surveys: Survey[];
}

export const initialState: State = {
    isLoading: false,
    addedSurvey: null,
    selectedSurvey: null,
    pagingInfo: EmptyPagingInfo(),
    surveys: []
};
