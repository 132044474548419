<cb-svg-definitions class="cb-svg-definitions"></cb-svg-definitions>
<cb-spinner [show]="isLoading || isChangingRoute || jwtInProgress"></cb-spinner>
<cb-event-log></cb-event-log>

<div
    [hidden]="jwtInProgress"
    class="main-page"
    *ngIf="!isLoading && !isPrintResponsePage && !isPreviewPage"
    [ngClass]="{ 'print-report': isPrintReport }"
>
    <div class="main-container">
        <mat-toolbar
            color="basic"
            *ngIf="!printMode && currentUserRoles && currentUserRoles.length"
            class="main-navigation"
        >
        <div class="mat-toolbar-content">
            <a class="toolbar-logo" [routerLink]="defaultUrl">
                <img
                    [src]="'assets/images/Checkbox.svg' | assetspipe"
                    alt="{{ 'ADMIN-MENU.LOGO' | translate }}"
                />
            </a>
            <div class="cb-toolbar-menu">
                <span
                    *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.SURVEY_ADMINISTRATOR,
                            userRoles.REPORT_ADMINISTRATOR,
                            userRoles.SURVEY_EDITOR
                        )
                    "
                    class="cb-toolbar-menu-item"
                >
                    <a
                        mat-button
                        routerLink="/surveys"
                        routerLinkActive="active"
                    >
                        {{ 'ADMIN-MENU.SURVEYS' | translate }}
                    </a>
                </span>
                <span
                    *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.SURVEY_ADMINISTRATOR,
                            userRoles.REPORT_ADMINISTRATOR,
                            userRoles.REPORT_VIEWER
                        )
                    "
                    class="cb-toolbar-menu-item"
                >
                    <a
                        mat-button
                        routerLink="/dashboards"
                        routerLinkActive="active"
                    >
                        {{ 'ADMIN-MENU.DASHBOARDS' | translate }}
                    </a>
                </span>
                <span
                    *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.CONTACT_ADMINISTRATOR
                        )
                    "
                    class="cb-toolbar-menu-item"
                >
                    <button
                        mat-button
                        [ngClass]="[isMainContactMenu() ? 'active' : '']"
                        [matMenuTriggerFor]="contactsMenu"
                        routerLinkActive="active"
                    >
                        {{ 'ADMIN-MENU.CONTACTS.CONTACTS' | translate }}
                    </button>
                </span>
                <span
                    *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.SURVEY_ADMINISTRATOR,
                            userRoles.SURVEY_EDITOR
                        )
                    "
                    class="cb-toolbar-menu-item"
                >
                    <a
                        mat-button
                        routerLink="/styles"
                        routerLinkActive="active"
                    >
                        {{ 'ADMIN-MENU.STYLES' | translate }}
                    </a>
                </span>
                <span
                    class="cb-toolbar-menu-item"
                    *ngIf="
                        hasRole(
                            userRoles.SYSTEM_ADMINISTRATOR,
                            userRoles.SURVEY_ADMINISTRATOR,
                            userRoles.SURVEY_EDITOR
                        )
                    "
                >
                    <a
                        mat-button
                        routerLink="/survey-templates"
                        routerLinkActive="active"
                    >
                        {{ 'ADMIN-MENU.SURVEY-TEMPLATES' | translate }}
                    </a>
                </span>
            </div>
            <span class="cb-toolbar-space"></span>
            <div class="cb-toolbar-user">
                <button
                    mat-button
                    [matMenuTriggerFor]="appMenu"
                    (menuClosed)="appMenuClose()"
                    (menuOpened)="appMenuOpen()"
                    [ngClass]="['menu-profile-control', isMenuOpened]"
                >
                    <cb-svg-icon class="icon" [name]="'profile'"></cb-svg-icon>
                    {{ userName }}
                    <span class="caret-icon">
                        <i class="fas fa-caret-down icon-down"></i>
                        <i class="fas fa-caret-up icon-up"></i>
                    </span>
                </button>
            </div>
        </div>

        </mat-toolbar>
        <mat-menu #appMenu="matMenu" overlapTrigger="false" xPosition="before">
            <button mat-menu-item (click)="clickOnMenuItem(EDIT_MY_INFO)">
                <span class="icon-wrap">
                    <cb-svg-icon
                        class="icon svg-icon"
                        [name]="'person'"
                    ></cb-svg-icon>
                </span>
                {{ 'EDIT-MY-INFO.MENU-ITEM' | translate }}
            </button>
            <button
                mat-menu-item
                (click)="clickOnMenuItem(UPGRADE_ACTION)"
            *ngIf="hasRole(userRoles.SYSTEM_ADMINISTRATOR) && isUpgradeable"
            >
                <span class="icon-wrap">
                    <cb-svg-icon
                        class="icon svg-icon"
                        [name]="'upgrade_account'"
                    ></cb-svg-icon>
                </span>
                {{ 'ADMIN-MENU.UPGRADE-ACCOUNT' | translate }}
            </button>
            <button
                mat-menu-item
                (click)="clickOnMenuItem(SETTINGS_ACTION)"
                *ngIf="hasRole(userRoles.SYSTEM_ADMINISTRATOR)"
            >
                <span class="icon-wrap">
                    <cb-svg-icon
                        class="icon svg-icon"
                        [name]="'settings'"
                    ></cb-svg-icon>
                </span>
                {{ 'ADMIN-MENU.ACCOUNT-SETTINGS' | translate }}
            </button>
            <button
                mat-menu-item
                (click)="clickOnMenuItem(SYSTEM_ACTION)"
                *ngIf="hasRole(userRoles.SYSTEM_ADMINISTRATOR)"
            >
                <span class="icon-wrap">
                    <cb-svg-icon
                        class="icon svg-icon"
                        [name]="'settings'"
                    ></cb-svg-icon>
                </span>
                System
            </button>
            <button mat-menu-item (click)="clickOnMenuItem(DOWNLOADS)">
                <span class="icon-wrap">
                    <cb-svg-icon
                        class="icon svg-icon"
                        [name]="'quick-export'"
                    ></cb-svg-icon>
                </span>
                {{ 'ADMIN-MENU.DOWNLOADS' | translate }}
            </button>
            <button
                mat-menu-item
                *ngIf="isMultiTenant"
                (click)="clickOnMenuItem(SUPPORT_ACTION)"
            >
                <span class="icon-wrap">
                    <cb-svg-icon
                        class="icon svg-icon"
                        [name]="'support'"
                    ></cb-svg-icon>
                </span>
                {{ 'ADMIN-MENU.CUSTOMER-SUPPORT' | translate }}
            </button>
            <button mat-menu-item (click)="helpGuides()">
                <span class="icon-wrap">
                    <cb-svg-icon
                        class="icon"
                        svg-icon
                        [name]="'support'"
                    ></cb-svg-icon>
                </span>
                {{ 'ADMIN-MENU.CUSTOMER-HELP' | translate }}
            </button>
            <button mat-menu-item (click)="clickOnMenuItem(SIGNOUT_ACTION)">
                <span class="icon-wrap">
                    <cb-svg-icon
                        class="icon svg-icon"
                        [name]="'logout'"
                    ></cb-svg-icon>
                </span>
                {{ 'ADMIN-MENU.LOG-OUT' | translate }}
            </button>
        </mat-menu>
        <mat-menu #contactsMenu="matMenu" overlapTrigger="false">
            <a
                mat-menu-item
                routerLink="/contacts"
                routerLinkActive="active"
            >
                <cb-svg-icon class="icon" [name]="'person'"></cb-svg-icon
                >{{ 'ADMIN-MENU.CONTACTS.CONTACTS' | translate }}
            </a>
            <a
                mat-menu-item
                routerLink="/groups"
                routerLinkActive="active"
            >
                <cb-svg-icon class="icon" [name]="'people'"></cb-svg-icon>
                {{ 'ADMIN-MENU.CONTACTS.GROUPS' | translate }}
            </a>
        </mat-menu>
        <div class="cb-app-content" [hidden]="isChangingRoute">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div class="print-section" *ngIf="isPrintResponsePage">
    <router-outlet [hidden]="isChangingRoute"></router-outlet>
</div>
<div class="print-section" *ngIf="isPreviewPage">
    <router-outlet></router-outlet>
</div>
