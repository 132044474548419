import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from './reducers';
import { surveys } from './actions';
import { PagedList, PagingInfo } from '../../infrastructure/models';
import { Survey, SurveyListGetData } from '../models';
import {
    getAddedSurveyInSurveysList,
    getSurveysLoadingStatus,
    getSurveysList,
    getSurveysPageInfo,
    getSelectedSurvey
} from './selectors';

@Injectable()
export class SurveysStore {
    constructor(private store: Store<AppState>) {}

    get addedSurvey(): Observable<Survey> {
        return this.store.select(getAddedSurveyInSurveysList);
    }

    get surveysList(): Observable<Survey[]> {
        return this.store.select(getSurveysList);
    }

    get pagingInfo(): Observable<PagingInfo> {
        return this.store.select(getSurveysPageInfo);
    }

    get selectedSurvey(): Observable<Survey> {
        return this.store.select(getSelectedSurvey);
    }

    get isLoading(): Observable<boolean> {
        return this.store.select(getSurveysLoadingStatus);
    }

    doAddSurvey(survey: Survey): void {
        this.store.dispatch(new surveys.AddSurveyAction(survey));
    }

    doLoadSurveys(getData: SurveyListGetData): void {
        this.store.dispatch(new surveys.GetSurveysListAction(getData));
    }

    doSelectSurvey(survey: Survey): void {
        this.store.dispatch(new surveys.SelectSurveyAction(survey));
    }
}
