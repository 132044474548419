import { Action } from '@ngrx/store';

import { PagedList, PagingInfo } from '../../../infrastructure/models';
import { Survey, SurveyListGetData } from '../../models';

export const ADD_SURVEY = '[Survey] Add Survey';
export const ADD_SURVEY_SUCCESS = '[Survey] Add Survey Success';
export const GET_SURVEYS_LIST = '[Survey] Get Surveys List';
export const GET_SURVEYS_LIST_SUCCESS = '[Survey] Get Surveys List Success';
export const SURVEY_FAILED = '[Survey] Survey Failed';
export const SELECT_SURVEY = '[Survey] Select Survey';

export class AddSurveyAction implements Action {
    readonly type = ADD_SURVEY;

    constructor(public payload: Survey) {}
}

export class AddSurveySuccessAction implements Action {
    readonly type = ADD_SURVEY_SUCCESS;

    constructor(public payload: Survey) {}
}

export class GetSurveysListAction implements Action {
    readonly type = GET_SURVEYS_LIST;

    constructor(public payload: SurveyListGetData) {}
}

export class GetSurveysListSuccessAction implements Action {
    readonly type = GET_SURVEYS_LIST_SUCCESS;

    constructor(public payload: PagedList<Survey>) {}
}

export class SelectSurveyAction implements Action {
    readonly type = SELECT_SURVEY;

    constructor(public payload: Survey) {}
}

export class SurveyFailedAction implements Action {
    readonly type = SURVEY_FAILED;

    constructor() {}
}

export type Actions =
    | AddSurveyAction
    | AddSurveySuccessAction
    | GetSurveysListAction
    | GetSurveysListSuccessAction
    | SelectSurveyAction
    | SurveyFailedAction;
