import { first, map, skip } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationError, ExternalSignin, Identity, Signin } from '../../../infrastructure/models';
import { ErrorStore, IdentityStore } from '../../../infrastructure/store';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmailVerificationDialogComponent } from '../email-verification-dialog/email-verification-dialog.component';
import { SetTimeZoneDialogComponent } from '../set-time-zone-dialog/set-time-zone-dialog.component';
import { ContactEmailVerificationCase, ContactEmailVerificationCodeStatus } from '../../models/support/email-verification.model';
import { MfaTokenStorage } from '../../../infrastructure/providers/mfa-token-storage';
import { SetEmailDialogComponent } from '../set-email-dialog/set-email-dialog.component';
import { EmailVerificationProvider } from '../../providers/support/email-verification.provider';
import * as _ from 'lodash';
import { UserRoles } from '../../../infrastructure/consts/auth.consts';
import { ApplicationSettingsProvider } from '../../settings/application-settings/application-settings.provider';
import { PreferenceApplicationSettings } from '../../settings/application-settings/preferences-application-settings/perferences-application-settings.model';
import { JWTSignInType } from '../../../infrastructure/models/signInWithJWT.model';
import { ToasterService } from '../../../infrastructure/services';
import { ExternalLoginProvider } from '../../../app-admin/providers';

@Component({
    selector: 'cb-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
    identity: Observable<Identity>;
    lastError: Observable<ApplicationError>
    isLoading: boolean;
    identitySubscription: Subscription;
    lastErrorSubscription: Subscription;
    emailVerificationDialogRef: MatDialogRef<EmailVerificationDialogComponent>;
    setEmailDialogRef: MatDialogRef<SetEmailDialogComponent>;
    setTimeZoneDialogRef: MatDialogRef<SetTimeZoneDialogComponent>;
    signInData: Signin;

    constructor(
        private store: IdentityStore,
        private errorStore: ErrorStore,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private applicationSettingsProvider: ApplicationSettingsProvider,
        private mfaTokenStorage: MfaTokenStorage,
        private emailVerificationProvider: EmailVerificationProvider,
        private toasterService: ToasterService,
        private externalLoginProvider: ExternalLoginProvider
    ) {
        this.identity = this.store.identity;
        this.lastError = this.errorStore.lastError;
    }

    ngOnInit() {
        this.handleQueryParams();

        this.lastErrorSubscription = this.lastError.subscribe((error: any) => {
            if (error 
                && error.error 
                && this.signInData
                && this.signInData.id
            ) {
                if (error.error.error === 'unverified_email') {
                    this.showEmailVerificationDialog(this.signInData, ContactEmailVerificationCase.LoginEmailVerification, '');
                } else if (error.error.error === 'invalid_mfa_email_token') {
                    this.showEmailVerificationDialog(this.signInData, ContactEmailVerificationCase.MfaLogin, '');
                } else if(error.error.error === 'invalid_email') {
                    this.showSetEmailDialog();
                }
            }
        });
    }

    private prepareForRedirect(isJwtLogin: boolean) {
        this.identitySubscription = this.identity.pipe(skip(+isJwtLogin))
            .subscribe((identity: Identity) => {
                if (identity && identity.access_token) {
                    this.store.goToRedirectUri(identity);
                    if (!isJwtLogin && identity.roles && 
                        _.includes(identity.roles, UserRoles.SYSTEM_ADMINISTRATOR)) {
                        this.confirmAppTimeZone();
                    }
                }
            });
    }

    private handleQueryParams() {
        const queryParams = this.route.snapshot.queryParams;
        const { internal_access, jwt, destinationURL } = queryParams;

        if (jwt) {
            this.store.doSignInWithJWT({
                token: jwt,
                destinationURL,
                jwtType: internal_access === 'true' ? JWTSignInType.MasterSite : JWTSignInType.SingleSignOn
            });
            this.prepareForRedirect(true);
        } else {
            this.prepareForRedirect(false);

            this.parseGoogleParams()
                .pipe(first())
                .subscribe((data:ExternalSignin) => {
                    if (data != null) {
                        this.store.doExternalSignIn(data);

                    }
                },
                    err => {
                        this.errorHandler(err);
                        this.toasterService.showError({ message: 'Invalid token' }, true);
                    }
                );
        }
    }

    private parseGoogleParams():Observable<ExternalSignin> {     
        let split = location.hash ? location.hash.split('id_token=') : '';
        const token = split ? split[1].split('&')[0] : '';

        if (!token)
            return of(null);

        split = location.hash.split('state=');
        const state = split ? split[1].split('&')[0] : '';
        split = state ? state.split('account=') : '';
        const account = split ? split[1].split('&')[0] : '';
        
        return this.decodeJWTToken(token)
            .pipe(
                map((dict) => {
                    return {
                        account: account,
                        token: token, 
                        id: dict.email, 
                        provider_name: 'google' 
                    };
                })
            );
    }

    private errorHandler(error) {
        this.isLoading = false;
    }

    onLogin(user: Signin) {
        this.signInData = user;
        const mfaToken = this.mfaTokenStorage.getMfaEmailToken(user.id, user.account);
        this.doLogin(mfaToken);
    }

    onGoogleLogin(account: string) {
        window.location.href = this.externalLoginProvider.getGoogleSinginLink(account);
    }

    private showEmailVerificationDialog(data: Signin, verificationCase: ContactEmailVerificationCase, email: string) {
        this.emailVerificationDialogRef = this.dialog.open(
            EmailVerificationDialogComponent,
            {
                width: '536px',
                data: { 
                    contactId: data.id, 
                    verificationCase: verificationCase,
                    email: email,
                    password: data.password
                }
            }
        );
        
        this.emailVerificationDialogRef.afterClosed().subscribe(data => {
            if (data && data.status == ContactEmailVerificationCodeStatus.Valid) {
                if (data.token) {
                    this.mfaTokenStorage.setMfaEmailToken(this.signInData.id, this.signInData.account, data.token);
                    this.doLogin(data.token);   
                } else if(data.code) {
                    this.emailVerificationProvider.issueToken(this.signInData.id, data.code)
                    .pipe(first())
                    .subscribe(res => {
                        this.mfaTokenStorage.setMfaEmailToken(this.signInData.id, this.signInData.account, res.token);
                        this.doLogin(res.token);            
                    });
                }  
            }
        });
    }

    private showSetEmailDialog() {
        this.setEmailDialogRef = this.dialog.open(
            SetEmailDialogComponent,
            {
                width: '536px'
            }
        );

        this.setEmailDialogRef.afterClosed().subscribe(email => {
            if (email) {
                this.showEmailVerificationDialog(this.signInData, ContactEmailVerificationCase.LoginEmailVerification, email);
            }
        });
    }

    private doLogin(mfaEmailToken: string) {
        const data = Object.assign({}, this.signInData, {mfa_email_token: mfaEmailToken} );
        this.store.doSignIn(data);
    }

    private confirmAppTimeZone() {
        this.applicationSettingsProvider.getSystemPreferences()
            .pipe(first())
            .subscribe((preferences: PreferenceApplicationSettings) => {
                if (!preferences.time_zone_id || !preferences.time_zone_confirmed) {
                    this.setTimeZoneDialogRef = this.dialog.open(
                        SetTimeZoneDialogComponent,
                        {
                            width: '600px',
                            data: { 
                                preferences: preferences
                            },
                            disableClose: true
                        }
                    );
                }
            });
    }

    private decodeJWTToken(jwt: string) {
        const jwtKeys = ['email'];

        const payload = jwt.split('.')[1];
        const parsedPayload = JSON.parse(atob(payload));
        if (_.every(jwtKeys, key => parsedPayload[key])) {
            return of({
                email: parsedPayload.email
            });
        } else {
            return throwError({ message: 'Invalid token' });
        }
    }

    ngOnDestroy(): void {
        if (this.identitySubscription) {
            this.identitySubscription.unsubscribe();
        }
        if (this.lastErrorSubscription) {
            this.lastErrorSubscription.unsubscribe();
        }
    }
}
