import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HostedPage } from '../models/hosted-page';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChargebeeUrlPaymentDialogComponent } from '../chargebee-url-payment-dialog/chargebee-url-payment-dialog.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderProvider } from '../providers/order/order-provider.service';
import { AutoUnsubscribe } from '../../shared/decorators/autoUnsubscribe.decorator';
import { ResultDialogComponent } from '../result-dialog/result-dialog.component';

@Component({
    // tslint:disable-next-line:component-selector
  selector: 'app-chargebee-dialog',
  templateUrl: './chargebee-dialog.component.html',
  styleUrls: ['./chargebee-dialog.component.scss']
})
@AutoUnsubscribe()
export class ChargebeeDialogComponent implements OnInit, OnDestroy {

  constructor(private $http: HttpClient,
              private dialog: MatDialog,
              private newDialog: MatDialog,
              public dialogChargebee: MatDialogRef<ChargebeeDialogComponent>,
              private orderProviderService: OrderProvider,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  hostedPage: HostedPage;
  private componentDestroyed = new Subject();
  private dialogRef;

  ngOnInit(): void {
  }

  openChargebeeCheckoutUrl(item: string) {
    this.orderProviderService.getChargebeeCheckoutUrl(item).pipe(takeUntil(this.componentDestroyed))
    .subscribe(response => {
        this.hostedPage = response;
        this.dialogChargebee.close();
        this.dialogRef = this.newDialog.open(ChargebeeUrlPaymentDialogComponent, {
          data: {hostedPageData : response, dataItem: item}
        });
        this.dialogRef.afterClosed()
        .subscribe(() => {
            this.checkStatusHostedPage(this.hostedPage, item);
        });
      }
      );

  }

  checkStatusHostedPage(hostedPage: HostedPage, product: string) {
    this.orderProviderService.getStatusHostedPage(hostedPage).pipe(takeUntil(this.componentDestroyed))
    .subscribe(response => {
        if (response.toLowerCase() === 'succeeded' || response === '3') {
          this.getCart(product);
        }
      });
  }

  getCart(product: string) {
      this.orderProviderService.getCart(product).pipe(takeUntil(this.componentDestroyed))
      .subscribe(response => {
          this.upgrade(response.CartGuid);
        });
  }

  upgrade(cartGuid: string) {
    const domain = this.data.domain;
    const applicationContextGuid = this.data.applicationContextGuid;

    this.orderProviderService.postUpgrade(cartGuid, domain, applicationContextGuid).pipe(takeUntil(this.componentDestroyed))
    .subscribe(response => {
        const result = 'Upgrade Success!';
        this.dialogRef = this.dialog.open(ResultDialogComponent, {
          data: {result: result}
          });
          this.dialogRef.afterClosed().subscribe((result) => {
            this.dialog.closeAll();
          });
      });
  }

  ngOnDestroy() { }

}
