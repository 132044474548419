import { FormGroup, FormControl } from '@angular/forms';
import { NetPromoterScoreItem } from '../../../models/survey-items/question-items/netPromoterScoreItem';
import * as _ from 'lodash';
import { getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../../infrastructure/services/shared.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-net-promoter-score-item-preview',
    templateUrl: './net-promoter-score-item-preview.component.html',
    styleUrls: ['./net-promoter-score-item-preview.component.scss']
})
@AutoUnsubscribe()
export class NetPromoterScorePreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: NetPromoterScoreItem;
    @Output() updated = new EventEmitter<any>();
    form: FormGroup;
    lastIndex: number;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    private componentDestroyed = new Subject();
    questionId = TakeSurveyData.QUESTION_ID;

    constructor(
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private printService: PrintService,
        private elem: ElementRef
        ) { }

    ngOnInit() {
        const defaultChoiceIndex = (this.lastIndex = _.findLastIndex(
            this.questionItem.choices,
            { is_default: true }
        ));
        const defaultChoiceValue =
            defaultChoiceIndex !== -1
                ? this.questionItem.choices[defaultChoiceIndex].id
                : null;
        this.form = new FormGroup({
            id: new FormControl(this.questionItem.id),
            value: new FormControl(defaultChoiceValue)
        });

        this.updated.emit(this.form.value);

        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => this.updated.emit(s));

        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    ngAfterViewInit(): void {
        this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
    }
    
    resetForm() {
        this.form.get('value').setValue('');
    }

    isNoneSelected() {
        return _.every(this.questionItem.choices, { is_default: false });
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }
}
