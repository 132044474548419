import { QuestionItem } from './../../shared/models/survey-items/question-items/questionItem';
import * as _ from 'lodash';
import { ValidationObject } from '../models/validation-object.model';
import {
    SurveyActionType,
    SurveyAnswerFormatType,
    SurveyDisplayType,
    SurveyQuestionType,
    SurveyRankOrderType,
    SurveySliderValueType,
    SurveyExpressionsSourceType,
    SurveyExpressionsOperandType,
    SurveyExpressionsOperatorType
} from '../consts/surveys.consts';
import { SingleLineItem } from '../../shared/models/survey-items/question-items/singleLineItem';
import { isNull } from 'util';
import * as moment from 'moment';
import { AbstractControl } from '@angular/forms';
import { StringPatterns } from '../consts/string-patterns.const';

const errorType = 'SurveyEditor';

export class SurveyEditorValidator {
    static questionType = SurveyQuestionType;
    static answerType = SurveyAnswerFormatType;
    static actionType = SurveyActionType;
    static sliderValueType = SurveySliderValueType;
    static rankOrderType = SurveyRankOrderType;
    static errors: ValidationObject[] = [];
    static alphaNumericPattern = StringPatterns.alphaNumericPattern;
    static alphaPattern = StringPatterns.alphaPattern;
    static decimalNumericPattern = StringPatterns.decimalNumericPattern;
    static emailPattern = StringPatterns.emailPattern;
    static mergePattern = StringPatterns.mergePattern;
    static localMergePattern = StringPatterns.localMergePattern;
    static numericPattern = StringPatterns.numericPattern;
    static moneyPattern = StringPatterns.moneyPattern;
    static allNumbersPattern = StringPatterns.allNumbersPattern;
    static USorCanadianPostalPattern = StringPatterns.USorCanadianPostalPattern;
    static northAmericaPhoneNumber = StringPatterns.northAmericaPhoneNumber;
    static SSNpattern = StringPatterns.SSNpattern;
    static upperCasePattern = StringPatterns.upperCasePattern;
    static lowerCasePattern = StringPatterns.lowerCasePattern;
    static URLpattern = StringPatterns.URLpattern;
    static fromAngleBracketsPattern = StringPatterns.fromAngleBracketsPattern;

    static validateDefaultTextLength(itemData: SingleLineItem) {
        const defaultText = itemData.default_text || '';
        const maxLength = itemData.max_length;
        if (
            !(
                _.isNull(maxLength) ||
                _.isUndefined(maxLength) ||
                !defaultText.length ||
                defaultText.length <= maxLength
            )
        ) {
            SurveyEditorValidator.addError(
                'Default Text value is longer than specified maximum length.'
            );
        }
    }

    static validateRegEx(itemData: SingleLineItem) {
        const defaultText = itemData.default_text || '';
        const maxLength = itemData.max_length;
        if (!itemData.regex_validation_message) {
            SurveyEditorValidator.addError(
                'Validation message field is required'
            );
        }
        if (!itemData.regex_pattern) {
            SurveyEditorValidator.addError(
                'RegEx field is required'
            );
        }
    }

    static validateNumericInput(event, isDate = null, isDecimal = null) {
        const charCode = event.which ? event.which : event.keyCode;
        if (
            charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            (charCode < 96 || charCode > 105) &&
            (charCode !== 45) &&
            (isDate ? charCode !== 47 : true) &&
            (isDecimal ? charCode !== 46 : true) &&
            (isDecimal ? charCode !== 44 : true)
        ) {
            return false;
        }
        return true;
    }

    static validateMinMaxValue(itemData, isData = false) {
        let min_length, max_length, choices_count;
        switch (itemData.item_type) {
            case SurveyEditorValidator.questionType.SINGLE_LINE_TEXT:
                if (isData) {
                    min_length = Date.parse(itemData.min_value);
                    max_length = Date.parse(itemData.max_value);
                } else {
                    min_length = itemData.min_value;
                    max_length = itemData.max_length || itemData.max_value;
                }
                break;
            case SurveyEditorValidator.questionType.SLIDER:
                min_length = itemData.min_value;
                max_length = itemData.max_value;
                break;
            case SurveyEditorValidator.questionType.MULTI_LINE_TEXT:
                min_length = itemData.min_length;
                max_length = itemData.max_length;
                break;
            case SurveyEditorValidator.questionType.CHECKBOXES:
            case SurveyEditorValidator.questionType.IMAGE_CHOICE:
                min_length = itemData.select_min_limit;
                max_length = itemData.select_max_limit;
                choices_count = itemData.choices.length;
                break;
            case SurveyEditorValidator.questionType.RANKORDER:
                min_length = itemData.min_to_rank;
                max_length = itemData.max_to_rank;
                choices_count = itemData.choices.length;
                break;
            default:
                break;
        }
        const notNumberFormat = itemData.answer_format !== 'Decimal'
                            && itemData.answer_format !== 'Integer'
                            && itemData.answer_format !== 'Numeric';

        if ( _.isNull(min_length) && _.isNull(max_length)) {
            return;
        }

        if (notNumberFormat && !_.isUndefined(max_length) && !_.isNull(max_length) && max_length.toString() === '0') {
            SurveyEditorValidator.addError(
                'Max option  can\'t be equal to 0'
            );
        }

        switch (itemData.item_type) {
            case SurveyEditorValidator.questionType.SINGLE_LINE_TEXT:
                 if (!isData && notNumberFormat && min_length < 0) {
                    SurveyEditorValidator.addError(
                        'Minimum value can\'t be less than 0.'
                    );
                }
                if (!isData && notNumberFormat && !_.isNull(max_length) && max_length < 1) {
                    SurveyEditorValidator.addError(
                        'Maximum value can\'t be less than 1.'
                    );
                }
                if (min_length && max_length && min_length > max_length) {
                    SurveyEditorValidator.addError(
                        'Minimum value should be less or equal than maximum value.'
                    );
                }
            break;
            case SurveyEditorValidator.questionType.MULTI_LINE_TEXT:
            case SurveyEditorValidator.questionType.SLIDER:
                if (min_length > max_length) {
                    SurveyEditorValidator.addError(
                        'Minimum value should be less or equal than maximum value.'
                    );
                }
                break;
            case SurveyEditorValidator.questionType.CHECKBOXES:
            case SurveyEditorValidator.questionType.IMAGE_CHOICE:
            case SurveyEditorValidator.questionType.RANKORDER:
                if (!_.isNull(max_length) && 1 > max_length) {
                    SurveyEditorValidator.addError(
                        'Max option to choose can\'t be less than 1'
                    );
                }
                if (0 > min_length) {
                    SurveyEditorValidator.addError(
                        'Min option to choose can\'t be less than 0'
                    );
                }
                if (choices_count < max_length) {
                    SurveyEditorValidator.addError(
                        'Max option to choose can\'t be higher than answer choices'
                    );
                }
                if (choices_count < min_length) {
                    SurveyEditorValidator.addError(
                        'Min option to choose can\'t be higher than options choices'
                    );
                }
                if (max_length && max_length < min_length) {
                    SurveyEditorValidator.addError(
                        'Min option to choose can\'t be higher max options to choose'
                    );
                }
                break;
            default:
                break;
        }
    }

    static validateNegativeWidth(width) {
        if (width < 0) {
            SurveyEditorValidator.addError(
                'Width can\'t be negative'
            );
        }
    }

    static validateNegativeHeight(height) {
        if (height < 0) {
            SurveyEditorValidator.addError(
                'Height can\'t be negative'
            );
        }
    }

    static validateRange(itemData) {
        if (
            itemData.value_type ===
            SurveyEditorValidator.sliderValueType.NUMBER_RANGE
        ) {
            const { min_value, max_value, default_value } = itemData;
            if (_.some([min_value, max_value, default_value], isNull)) {
                return;
            }
            if (!_.inRange(default_value, min_value, max_value + 1)) {
                SurveyEditorValidator.addError(
                    'Default value should be between minimum and maximum values.'
                );
            }
        }
    }

    static validateRankOptions(itemData) {
        SurveyEditorValidator.validateChoicesNumber(itemData);
        if (itemData.option_type === 'Image') {
            this.validateAllias(itemData);
        } else if (
            itemData.rank_order_type ===
            SurveyEditorValidator.rankOrderType.NUMERIC
        ) {
            const { max_to_rank } = itemData;
            if (_.isNull(max_to_rank) || !max_to_rank.length) {
                return;
            }
            if (+max_to_rank < 2) {
                SurveyEditorValidator.addError(
                    'Must be 2 or more options to rank.'
                );
            }
        }
    }

    static validateAllias(itemData) {
        itemData.choices.map(item => {
            if (!item.alias) {
                SurveyEditorValidator.addError('Alias must be filled.');
            }
        });
    }

    static validatePoints(choices) {
        choices.map(item => {
            if (_.isNil(item.points)) {
                SurveyEditorValidator.addError('Points must be filled.');
            }
        });
        const choicesLength = choices.length;
        if (choicesLength > 1) {
            const firstStep = Math.round(choices[1].points - choices[0].points);
            const isPositive = Math.sign(firstStep) + 1;

            for (let i = 0; i < choicesLength - 1; ++i) {
                if (isPositive && Math.round(choices[i].points - choices[i + 1].points) !== -1
                    || !isPositive && Math.round(choices[i].points - choices[i + 1].points) !== 1) {
                    SurveyEditorValidator.addError(`Choices ${i + 1} and ${i + 2} are unordered or have gaps in points.`);
                }
            }
        }
    }

    static validateMinTwoChoices(itemData) {
        if (itemData.choices.length < 2) {
            SurveyEditorValidator.addError('Enter at least two options, please.');
        }
    }

    static checkDefaultTextForNullOrEmptyString(default_text) {
        return _.isNull(default_text) || default_text === '';
    }

    static validateItemDependingOnAnswerFormat(itemData: SingleLineItem) {
        const { answer_format, min_value, max_value, default_text } = itemData;
        switch (answer_format) {
            case SurveyEditorValidator.answerType.DATE_USA:
            case SurveyEditorValidator.answerType.DATE_ROTW:
                const _format =
                    answer_format === SurveyEditorValidator.answerType.DATE_USA
                        ? 'MM/DD/YYYY'
                        : 'DD/MM/YYYY';
                const isDate = _.every([min_value, max_value], date => {
                        if (date) {
                            return moment(moment(date).format(_format), _format).isValid();
                        } else {
                            return true;
                        }
                    }
                );
                if (!isDate) {
                    SurveyEditorValidator.addError(
                        `Minimum and maximum value must be a valid date in the format ${_format}`
                    );
                }
                break;
            case SurveyEditorValidator.answerType.ALPHA_NUMERIC:
                const isAlphaNumeric = default_text.match(
                    SurveyEditorValidator.alphaNumericPattern
                );
                if (
                    !isAlphaNumeric &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be a valid alpha-numeric string.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.ALPHA:
                const isAlpha = default_text.match(
                    SurveyEditorValidator.alphaPattern
                );
                if (
                    !isAlpha &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must contain only letters.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.DECIMAL:
                const isDecimal = _.every(
                    [`${min_value}`, `${max_value}`, default_text],
                    x => x.match(SurveyEditorValidator.decimalNumericPattern)
                );
                if (
                    !isDecimal &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Minimum, maximum value and default text must be a decimal number.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.EMAIL:
                const isEmail = default_text.match(
                    SurveyEditorValidator.emailPattern
                );
                if (
                    !isEmail &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be a valid email address.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.INTEGER:
                const isInteger = default_text.match(
                    SurveyEditorValidator.numericPattern
                );
                if (
                    !isInteger &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be a valid interger.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.MONEY:
                const isMoney = default_text.match(
                    SurveyEditorValidator.moneyPattern
                );
                if (
                    !isMoney &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be a US dollar amount, e.g., $40.31 or 3.99.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.NUMERIC:
                const isNumeric = default_text.match(
                    SurveyEditorValidator.allNumbersPattern
                );
                if (
                    !isNumeric &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be a valid number, e.g., 15.03, 133, 9283.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.POSTAL:
                const isUSOrCanadianPostal = default_text.match(
                    SurveyEditorValidator.USorCanadianPostalPattern
                );
                if (
                    !isUSOrCanadianPostal &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be a valid US or Canadian Postal Code.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.PHONE:
                const isNorthAmericaPhoneNumber = default_text.match(
                    SurveyEditorValidator.northAmericaPhoneNumber
                );
                if (
                    !isNorthAmericaPhoneNumber &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be a valid US phone number.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.SSN:
                const isSSN = default_text.match(
                    SurveyEditorValidator.SSNpattern
                );
                if (
                    !isSSN &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be a valid Social Security Number.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.UPPERCASE:
                const isUppercase = default_text.match(
                    SurveyEditorValidator.upperCasePattern
                );
                if (
                    !isUppercase &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be upper case letters.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.LOWERCASE:
                const isLowercase = default_text.match(
                    SurveyEditorValidator.lowerCasePattern
                );
                if (
                    !isLowercase &&
                    !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                        default_text
                    ) &&
                    !SurveyEditorValidator.isMergeCode(default_text)
                ) {
                    SurveyEditorValidator.addError(
                        'Default Text must be lower case letters.'
                    );
                }
                break;
            case SurveyEditorValidator.answerType.URL:
                if (SurveyEditorValidator.testBrokenURLpattern(default_text)) {
                    SurveyEditorValidator.addError(
                        'Default Text must be a valid URL.'
                    );
                }
                break;

            default:
                break;
        }
    }

    static isMergeCode(text: string) {
        // merge pattern has g flag, test method remembers the last matched place
        // if you run test several times you will have different results
        // TODO: check why we use g flag
        this.mergePattern.lastIndex = 0;
        return this.mergePattern.test(text.trim());
    }

    static testBrokenURLpattern(url) {
        const isURL = url.match(
            SurveyEditorValidator.URLpattern
        );
        return (
            !isURL &&
            !SurveyEditorValidator.checkDefaultTextForNullOrEmptyString(
                url
            ) &&
            !SurveyEditorValidator.isMergeCode(url)
        );
    }

    static validateAutoCompleteList(itemData: SingleLineItem) {
        if (_.isNull(itemData.autocomplete_list_name)) {
            SurveyEditorValidator.addError(
                'Autocomplete list name should be defined.'
            );
        }
    }

    static addError(message) {
        SurveyEditorValidator.errors.push({
            type: errorType,
            validationMessage: message
        });
    }

    static validateQuestionText(itemData: SingleLineItem) {
        if (!itemData.question_text) {
            SurveyEditorValidator.addError('Please, enter question text.');
        }
    }

    static validateMaxDiff(itemData: any) {
        SurveyEditorValidator.validateChoicesNumber(itemData);

        if (!itemData.number_sets || itemData.number_sets < 1) {
            SurveyEditorValidator.addError(
                'Please, enter correct number of sets.'
            );
        }

        if (
            itemData.number_items_per_set &&
            itemData.number_items_per_set > itemData.choices.length
        ) {
            SurveyEditorValidator.addError(
                'Number of choices per set should be lower or equal than number of choices.'
            );
        }
    }

    static validateFileUpload(itemData: any) {
        if (
            !(itemData.allowed_file_types && itemData.allowed_file_types.length)
        ) {
            SurveyEditorValidator.addError(
                'Please, select at least one allowed file extension.'
            );
        }
    }

    static validateChoicesNumber(itemData) {
        if (itemData.choices.length < 2) {
            SurveyEditorValidator.addError(
                'Please, enter at east two answer choices.'
            );
        }
    }

    static validateEmailString(emailString, emptyValid = false) {
        if (!emailString) {
            return emptyValid;
        }

        let isValid = true;
        const emails = emailString.split(',');

        if (emails.length === 1 && emails[0].trim().split(' ').length > 1) {
            SurveyEditorValidator.addError(
                'Please, separate emails by comma.'
            );
            return true;
        }
        emails.forEach(email => {
            const clearEmail = email.trim();
            if (clearEmail.split(' ').length > 1 ) {
                SurveyEditorValidator.addError(
                    'Please, separate emails by comma.'
                );
                return true;
            }
            if (!this.emailPattern.test(clearEmail) && !this.localMergePattern.test(clearEmail)) {
                isValid = false;
            }
        });

        return isValid;
    }
    static validateEmailItem(itemData: any) {

        if (!this.validateEmailString(itemData.to)) {
            SurveyEditorValidator.addError(
                'Please, enter a valid recipient email.'
            );
        }
        const from  = this.fromAngleBracketsPattern.exec(itemData.from) ?
                    this.fromAngleBracketsPattern.exec(itemData.from)[1] : itemData.from;
        if (
            !(
                from &&
                (this.emailPattern.test(from) || SurveyEditorValidator.isMergeCode(from))
            )
        ) {
            SurveyEditorValidator.addError(
                'Please, enter a valid sender email.'
            );
        }

        if (!this.validateEmailString(itemData.bcc, true)) {
            SurveyEditorValidator.addError(
                'Please, enter a valid bcc email.'
            );
        }

        if (!itemData.subject) {
            SurveyEditorValidator.addError('Please, enter a subject');
        }
        if (itemData.item_type === 'Email' && !itemData.body) {
            SurveyEditorValidator.addError('Please, enter body of email');
        }
    }

    static validateRedirectItem(itemData: any) {
        if (!itemData.restart_survey) {
            if ( !itemData.url || SurveyEditorValidator.testBrokenURLpattern(itemData.url)) {
                SurveyEditorValidator.addError(
                    'Please, enter a valid redirect url.'
                );
            }
            if (
                itemData.auto_redirect &&
                (typeof itemData.auto_redirect_delay !== 'number' ||
                    itemData.auto_redirect_delay < 0)
            ) {
                SurveyEditorValidator.addError(
                    'Please, enter a valid redirect delay.'
                );
            }
        }
        if (!itemData.auto_redirect && (!itemData.text || (itemData.text && !itemData.text.trim().length))) {
            SurveyEditorValidator.addError(
                'Please, enter hyperlink text.'
            );
        }
    }

    static validateWebhookItem(itemData: any) {
        if ( !itemData.url || SurveyEditorValidator.testBrokenURLpattern(itemData.url)) {
            SurveyEditorValidator.addError(
                'Please, enter a valid webhook url.'
            );
        }
    }

    static validateSliderData(itemData) {
        if (
            _.isUndefined(itemData.min_value) ||
            _.isNull(itemData.min_value)
        ) {
            SurveyEditorValidator.addError('Please, specify minimum value.');
        }
        if (!itemData.max_value) {
            SurveyEditorValidator.addError('Please, specify maximum value.');
        }
        if (!itemData.step_size) {
            SurveyEditorValidator.addError('Please, specify step size.');
        }
        if (!itemData.step_size || !itemData.max_value || !itemData.min_value) {
            return;
        }
        if (itemData.step_size > itemData.max_value - itemData.min_value) {
            SurveyEditorValidator.addError('Please, decrease step size.');
        }
        if (itemData.step_size < 0) {
            SurveyEditorValidator.addError('Step size can\'t be less then 1');
        }
    }

    static getControlErrorsText(control: AbstractControl) {
        const errors = control.errors;
        if (errors) {
            if (errors.min) {
                return `This value should be greater than ${errors.min.min}`;
            }
            if (errors.max) {
                return `This value should be lower than ${errors.max.max}`;
            }
            if (errors.required) {
                return `This value is required`;
            }
        }
    }

    static validateImageItem(itemData) {
        if (!itemData.image_id) {
            SurveyEditorValidator.addError('Please, upload an image.');
        }
    }

    static validate(itemData: any): ValidationObject[] {
        SurveyEditorValidator.errors = [];
        switch (itemData.item_type) {
            case SurveyEditorValidator.questionType.RATINGSCALE:
                SurveyEditorValidator.validateNegativeWidth(itemData.option_width);
                SurveyEditorValidator.validatePoints(itemData.choices);
                SurveyEditorValidator.validateMinTwoChoices(itemData);
                break;
            case SurveyEditorValidator.questionType.SINGLE_LINE_TEXT:
                SurveyEditorValidator.validateAutoCompleteList(itemData);
                SurveyEditorValidator.validateDefaultTextLength(itemData);
                if (itemData.answer_format === SurveyEditorValidator.answerType.REGEX) {
                    SurveyEditorValidator.validateRegEx(itemData);
                }
                SurveyEditorValidator.validateItemDependingOnAnswerFormat(
                    itemData
                );
                SurveyEditorValidator.validateNegativeWidth(itemData.width);
                if (itemData.answer_format === SurveyEditorValidator.answerType.DATE_USA ||
                    itemData.answer_format === SurveyEditorValidator.answerType.DATE_ROTW) {
                        SurveyEditorValidator.validateMinMaxValue(itemData, true);
                    } else {
                        SurveyEditorValidator.validateMinMaxValue(itemData);
                    }
                break;
            case SurveyEditorValidator.questionType.MULTI_LINE_TEXT:
                SurveyEditorValidator.validateMultiLine(itemData);
                SurveyEditorValidator.validateMinMaxValue(itemData);
                break;
            case SurveyEditorValidator.questionType.CHECKBOXES:
                SurveyEditorValidator.validateCheckbox(itemData);
                SurveyEditorValidator.validateMinMaxValue(itemData);
                break;
            case SurveyEditorValidator.questionType.IMAGE_CHOICE:
                SurveyEditorValidator.validateMinMaxValue(itemData);
                SurveyEditorValidator.validateAllias(itemData);
                SurveyEditorValidator.validateNegativeWidth(itemData.image_width);
                SurveyEditorValidator.validateNegativeHeight(itemData.image_height);
                break;
            case SurveyEditorValidator.questionType.SLIDER:
                if (itemData.value_type === 'Image') {
                    this.validateAllias(itemData);
                }
                if (
                    itemData.value_type ===
                    SurveyEditorValidator.sliderValueType.NUMBER_RANGE
                ) {
                    SurveyEditorValidator.validateQuestionText(itemData);
                    SurveyEditorValidator.validateSliderData(itemData);
                    SurveyEditorValidator.validateMinMaxValue(itemData);
                    SurveyEditorValidator.validateRange(itemData);
                }
                SurveyEditorValidator.validateNegativeWidth(itemData.width);
                break;
            case SurveyEditorValidator.questionType.RATING:
                SurveyEditorValidator.validateRating(itemData);
                SurveyEditorValidator.validateChoicesNumber(itemData);
                SurveyEditorValidator.validateNegativeWidth(itemData.width);
                break;
            case SurveyEditorValidator.questionType.RANKORDER:
                SurveyEditorValidator.validateMinMaxValue(itemData);
                SurveyEditorValidator.validateRankOptions(itemData);
                break;
            case SurveyEditorValidator.questionType.MAXDIFF:
                SurveyEditorValidator.validateMaxDiff(itemData);
                break;
            case SurveyEditorValidator.questionType.FILE_UPLOAD:
                SurveyEditorValidator.validateFileUpload(itemData);
                break;
            case SurveyEditorValidator.actionType.EMAIL_ALERT:
            case SurveyEditorValidator.actionType.EMAIL_RESPONSE:
                SurveyEditorValidator.validateEmailItem(itemData);
                break;
            case SurveyEditorValidator.actionType.WEBHOOK:
                SurveyEditorValidator.validateWebhookItem(itemData);
                break;
            case SurveyEditorValidator.actionType.REDIRECT:
                SurveyEditorValidator.validateRedirectItem(itemData);
                break;
            case SurveyDisplayType.IMAGE:
                SurveyEditorValidator.validateImageItem(itemData);
                break;
            case SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST:
                SurveyEditorValidator.validateCustomSourceDropdown(itemData);
                break;
            case SurveyEditorValidator.questionType.RADIOBUTTONS:
                SurveyEditorValidator.validateRadiobuttons(itemData);
                break;
            case SurveyDisplayType.SCORING_MESSAGE:
                SurveyEditorValidator.validateScoringMessage(itemData);
                break;
            default:
                break;
        }
        this.validateConditions(itemData);
        return SurveyEditorValidator.errors;
    }

    static validateRadiobuttons(itemData) {
        if (!_.isNull(itemData.columns) && itemData.columns < 1) {
            SurveyEditorValidator.addError(
                'Columns can\'t be less than 1'
            );
        }
    }

    static validateMultiLine(itemData) {
        if (!_.isNull(itemData.rows) && itemData.rows < 1) {
            SurveyEditorValidator.addError(
                'Rows can\'t be less than 1'
            );
        }
        if (!_.isNull(itemData.cols) && itemData.cols < 1) {
            SurveyEditorValidator.addError(
                'Cols can\'t be less than 1'
            );
        }
    }

    static validateRating(itemData) {
        if (itemData.rating_type === 'Star' && (!_.isNull(itemData.option_size) && itemData.option_size < 1)) {
            SurveyEditorValidator.addError(
                'Star size can\'t be less than 1'
            );
        }
    }

    static validateCheckbox(itemData) {
        if (!_.isNull(itemData.columns) && itemData.columns < 1) {
            SurveyEditorValidator.addError(
                'Column counter can\'t be less than 1'
            );
        }
    }

    static validateCustomSourceDropdown(itemData) {
        if (!itemData.choice_list_url || SurveyEditorValidator.testBrokenURLpattern(itemData.choice_list_url)) {
            SurveyEditorValidator.addError(
                '"Choice list url" must be a valid URL.'
            );
        }
    }

    static validateConditions(itemData: any): void {
        if (
            itemData.include_condition &&
            itemData.include_condition.groups &&
            itemData.include_condition.groups.length
        ) {
            itemData.include_condition.groups.forEach(g => {
                if (g.expressions && g.expressions.length) {
                    g.expressions = g.expressions.filter(expr => {
                        if (
                            expr.source !== SurveyExpressionsSourceType.DEFAULT
                        ) {
                            if (
                                expr.question ===
                                SurveyExpressionsSourceType.DEFAULT
                            ) {
                                SurveyEditorValidator.addError(
                                    'Please, select condition question.'
                                );
                            } else if (
                                expr.operator ===
                                SurveyExpressionsSourceType.DEFAULT
                            ) {
                                SurveyEditorValidator.addError(
                                    'Please, select condition operator.'
                                );
                            } else if (
                                expr.operand_type ===
                                    SurveyExpressionsOperandType.CHOICE &&
                                !expr.value &&
                                (expr.operator ===
                                    SurveyExpressionsOperatorType.EQUAL ||
                                    expr.operator ===
                                        SurveyExpressionsOperatorType.NOT_EQUAL)
                            ) {
                                SurveyEditorValidator.addError(
                                    'Please, set condition operator value.'
                                );
                            }
                            return true;
                        }
                        return false;
                    });
                }
            });
        }
    }

    static validateScoringMessage(itemData) {
        if (itemData.messages) {
            itemData.messages.forEach(item => {
                if (Number(item.high_score) < Number(item.low_score)) {
                    SurveyEditorValidator.addError(
                        'Minimum score should be less or equal than maximum score.'
                    );
                }
            });
        }
    }
}
