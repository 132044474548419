import { QuestionItem } from './questionItem';

export class MultilinesItem extends QuestionItem {
    max_length: number;
    min_length: number;
    default_text: string;
    question_text_position: string;
    item_position: string;
    allow_html: boolean;
    rows: number;
    cols: number;
    alias: string;
    answer_text?: string;
}
