import { merge as observableMerge, Observable, of } from 'rxjs';

import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { error, surveys } from '../actions';
import { AppState } from '../reducers';
import { getSurveysPageInfo } from '../selectors';
import { PagedList } from '../../../infrastructure/models';
import { Survey, SurveyListGetData } from '../../models';
import { SurveyListProvider, SurveysProvider } from '../../providers';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class SurveyEffect {
    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private surveysProvider: SurveysProvider,
        private surveyListProvider: SurveyListProvider
    ) {}

    @Effect()
    addSurvey$: Observable<Action> = this.actions$.pipe(
        ofType<surveys.AddSurveyAction>(surveys.ADD_SURVEY),
        withLatestFrom(this.store$.select(getSurveysPageInfo)),
        map(data => ({ survey: data[0].payload, pagingInfo: data[1] })),
        switchMap((data: any) =>
            this.surveysProvider.addSurvey(data.survey).pipe(
                map((s: Survey) => new surveys.AddSurveySuccessAction(s)),
                mergeMap(() =>
                    of(new surveys.GetSurveysListAction(data.pagingInfo))
                ),
                catchError(err =>
                    observableMerge(
                        of(new error.HandleErrorAction(err)),
                        of(new surveys.SurveyFailedAction())
                    )
                )
            )
        )
    );

    @Effect()
    loadSurveys$: Observable<Action> = this.actions$.pipe(
        ofType<surveys.GetSurveysListAction>(surveys.GET_SURVEYS_LIST),
        map(action => action.payload),
        switchMap((getData: SurveyListGetData) =>
            this.surveyListProvider.loadSurveys(getData).pipe(
                map(
                    (s: PagedList<Survey>) =>
                        new surveys.GetSurveysListSuccessAction(s)
                ),
                catchError(err =>
                    observableMerge(
                        of(new error.HandleErrorAction(err)),
                        of(new surveys.SurveyFailedAction())
                    )
                )
            )
        )
    );
}
