import { CustomSourceDropdownListItem } from '../../../models/survey-items/question-items/customSourceDropdownListItem';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ItemOption } from '../../../models/survey-items/question-items/itemOption';
import { getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { ComponentPrintStatus, SharedService } from '../../../../infrastructure/services';
import { CustomSourceDropdownlistProvider } from '../../../../infrastructure/providers';
import { environment } from '../../../../environments/environment';
import { Subscription, Subject } from 'rxjs';
import { StringPatterns } from '../../../../infrastructure/consts/string-patterns.const';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { first, takeUntil } from 'rxjs/operators';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-custom-source-dropdownlist-item-preview',
    templateUrl: './custom-source-dropdownlist-item-preview.component.html',
    styleUrls: ['./custom-source-dropdownlist-item-preview.component.scss']
})
@AutoUnsubscribe()
export class CustomSourceDropDownListPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: CustomSourceDropdownListItem;
    @Input() isMatrix: boolean;
    @Input() isPrint: boolean;
    @Output() updated = new EventEmitter<any>();

    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    choices = [];
    isLoading: boolean;
    imagesLoaded: boolean;
    form: FormGroup;
    otherValue: ItemOption;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    brokenChoices: boolean;
    isAdmin = environment.isAdminSite;
    private componentDestroyed = new Subject();
    questionId = TakeSurveyData.QUESTION_ID;

    constructor(
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private customSourceDropdownlistProvider: CustomSourceDropdownlistProvider,
        private printService: PrintService,
        private elem: ElementRef) {}

    ngOnInit() {
        this.getChoices();

        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            this.detectChanges();
        });
    }

    ngAfterViewInit(): void {
        this.printService.waitForImageLoading(this.elem)
            .pipe(first())
            .subscribe(() => {
                this.imagesLoaded = true;
                this.markReadyForPrint();
            });
    }

    private getChoices() {
        this.isLoading = true;
        let url = this.questionItem.choice_list_url;
        if (this.isAdmin && StringPatterns.mergePattern.test(url.trim())) {
            url.match(StringPatterns.mergePattern).forEach(code => {
                const re = new RegExp(code, 'g');
                url = url.replace(re, '');
            });
        }
        const valueField = this.questionItem.value_field;
        this.customSourceDropdownlistProvider.getChoices(url, valueField).subscribe(
            (items: any) => {
                this.choices = items;
                this.createFormGroup();
                this.isLoading = false;
                this.detectChanges();
                this.markReadyForPrint();
            },
            error => {
                this.createFormGroup();
                this.isLoading = false;
                this.brokenChoices = true;
                this.detectChanges();
                this.markReadyForPrint();
            }
        );
    }

    private createFormGroup(): void {
        let defaultSelectedValue;
        let otherValue = null;
        if (this.questionItem.answer && this.questionItem.answer.value) {
            if ((this.choices.indexOf(this.questionItem.answer.value) === -1) && this.questionItem.allow_other) {
                defaultSelectedValue = this.questionItem.other_text;
                otherValue = this.questionItem.answer.value;
            } else {
                defaultSelectedValue = this.questionItem.answer.value;
            }
        }

        this.form = new FormGroup({
            id: new FormControl(this.questionItem.id),
            value: new FormControl(defaultSelectedValue),
            other_value: new FormControl(otherValue)
        });

        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                if (s.value !== this.questionItem.other_text) {
                    s.other_value = null;
                }
                this.updated.emit(s);
            });
        this.updated.emit(this.form.value);
    }

    resetForm() {
        this.form.get('value').reset();
        this.form.get('other_value').reset();
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }

    markReadyForPrint() {
        if (!this.isMatrix && this.imagesLoaded && !this.isLoading) {
            this.printService.setStatus(this.questionItem.id, ComponentPrintStatus.RENDERED);
        }
    }
}
