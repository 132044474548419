export const environment = {
  production: false,
  isAdminSite: true,
  isMultiTenant: true,
  disableSSO: false,
  name: "dev",
  containerId: 'GTM-TB77TJC',
  storageHubUrl: 'http://here_should_be_api_url_for_dev_env/hub.html',
  apiUrl: 'http://here_should_be_api_url_for_dev_env',
  googleAuthClientId: '',
  featureFlagEnvironmentID: 'FVK7WbSWTHL44t85UnJ8rP',
  featureFlagEnableCache: false
};
