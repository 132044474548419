import { QuestionItem } from './questionItem';

export class FileUploadItem extends QuestionItem {
    item_position: string;
    question_text_position: string;
    alias: string;
    file_name: string;
    file_size: number;
    file_id: string;
    answer: any;
    surveyId: number;
    responseId: string;
}
