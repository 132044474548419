import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AutoUnsubscribe } from '../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    // tslint:disable-next-line:component-selector
  selector: 'app-chargebee-url-payment-dialog',
  templateUrl: './chargebee-url-payment-dialog.component.html',
  styleUrls: ['./chargebee-url-payment-dialog.component.scss']
})
@AutoUnsubscribe()
export class ChargebeeUrlPaymentDialogComponent implements OnInit, OnDestroy {

  constructor(
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  url = 'https://checkbox.chargebee.com/pages/v3/ATZO8sDyMINhzhObljQn7AzKDW4hRAmG/';
  urlMap: SafeResourceUrl | undefined;

  ngOnInit(): void {
    this.urlMap = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.hostedPageData.Url);
  }

  ngOnDestroy() { }

}
