import { RenamePageComponent } from './rename-page/rename-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CustomMaterialModule } from '../../shared/modules/material-components.module';
import { SharedModule } from '../../shared/modules/shared.module';
import { ContactsSharedModule } from '../../shared/contacts/contacts-shared.module';
import { QuestionCollapsedPanel } from './question-collapsed-panel/question-collapsed-panel.component';
import { InfiniteListComponent } from './infiniteList/infiniteList.component';
import { ToggleSearchComponent } from './toggle-search/toggle-search.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { ReorderElementsDialogComponent } from './reorder-elements/reorder-elements-dialog.component';
import { BrowserDetectionService } from './browserDetection.service';
import { ShareUrlComponent } from './share-url/share-url.component';
import { ClipboardModule } from 'ngx-clipboard';
import { RestoreDataSettingsComponent } from '../surveys/survey-templates/restore-data-settings/restore-data-settings.component';
import { LazyTranslateLoaderModule } from '../entry-point/lazy-translate-loader.module';
import { SurveyLanguageSelectorComponent } from '../surveys/survey-edit/language-selector/language-selector.component';
import { HelpIconComponent } from './help-icon/help-icon.component';


const declarations = [
    RenamePageComponent, // editor and dashboard
    QuestionCollapsedPanel,
    InfiniteListComponent,
    ToggleSearchComponent,
    SearchInputComponent,
    ReorderElementsDialogComponent,
    ShareUrlComponent,
    RestoreDataSettingsComponent,
    SurveyLanguageSelectorComponent,
    HelpIconComponent
];
const entryComponents = [
    ReorderElementsDialogComponent,
    SurveyLanguageSelectorComponent
];

@NgModule({
    imports: [
        // system
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        ClipboardModule,
        // permissions
        NgxPermissionsModule.forRoot(),
        // translation
        LazyTranslateLoaderModule,
        // custom
        CustomMaterialModule,
        SharedModule,
        ContactsSharedModule
    ],
    declarations: declarations,
    exports: [...declarations, ...entryComponents],
    entryComponents: entryComponents,
    providers: [BrowserDetectionService]
})
export class AdminSharedModule {}
