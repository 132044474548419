import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { SurveyQuestionType } from '../../../../infrastructure/consts/surveys.consts';
import { ContactFormField, ContactFormItem, ContactFormResponseSubitem } from '../../../../shared/models/survey-items/question-items/contactFormItem';
import * as _ from 'lodash';
import { ValidationObject } from '../../../../infrastructure/models/validation-object.model';
import { getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { SinglelinesPreviewItemComponent } from '../singlelines-item-preview/singlelines-item-preview.component';
import { of, Subject, Subscription } from 'rxjs';
import { String } from 'typescript-string-operations';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';
import { SurveysSettingProvider } from '../../../../infrastructure/providers/surveys-setting.provider';
import { DrillDownItem, DrillDownLevel } from '../../../../shared/models/survey-items/question-items/drillDownItem';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { CustomSourceDropdownlistProvider } from '../../../../infrastructure/providers';
import { environment } from '../../../../environments/environment';
import { StringPatterns } from '../../../../infrastructure/consts/string-patterns.const';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { AutoUnsubscribe } from '../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

interface DrillDownSelection{
    level: DrillDownLevel;
    selectedIndex: number;
}
interface DrillDownSelectionFlat{
    id: string;
    description: string;
    level: DrillDownLevel;
    parent_id?: string;
}

@Component({
    selector: 'cb-drill-down-item-preview',
    templateUrl: './drill-down-item-preview.component.html',
    styleUrls: ['./drill-down-item-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class DrillDownItemPreviewComponent implements OnInit, OnDestroy {
    @Input() questionItem: DrillDownItem;
    @Input() isPrint: boolean;
    @Output() updated = new EventEmitter<any>();

    componentDestroyed = new Subject();
    isLoading: boolean;
    imagesLoaded: boolean;
    brokenChoices: boolean;
    isAdmin = environment.isAdminSite;
    choices = [];
    surveyText = SurveyDefaultText;
    currentLevel = 0;
    //creating a map bulleable numbers
    selections: Map<number, string> = new Map<number, string|null>();

    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    private _flatChoices: DrillDownSelectionFlat[] = [];
    form: FormGroup = new FormGroup({});

    constructor(
        private printService: PrintService,
        private surveysSettingProvider: SurveysSettingProvider,
        private elem: ElementRef,
        private customSourceDropdownlistProvider: CustomSourceDropdownlistProvider
        ) { }

    ngOnInit() {
        this.selections = this.questionItem.levels.reduce((map, level, index) => {
            map.set(index, null);
            return map;
        }, this.selections);
        this.initForms();
        //this.isLoading = true;
        let url = this.questionItem.choice_list_url;
        if (this.isAdmin && StringPatterns.mergePattern.test(url.trim())) {
            url.match(StringPatterns.mergePattern).forEach(code => {
                const re = new RegExp(code, 'g');
                url = url.replace(re, '');
            });
        }
        this.customSourceDropdownlistProvider.getChoices(url, null)
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(
            (items: any) => {
                this.choices = items;
                //this.initForms();
                this._flatChoices = this.getSelectionsFlat(this.choices);
                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
                this.brokenChoices = true;
            }
        );
    }

    ngOnDestroy() {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }
    initForms(){
        this.form = new FormGroup({
            levels: new FormArray(this.createFormGroup(this.questionItem.levels))
        });
    }
    showColumn(levelIndex: number): boolean {
        return this.questionItem.levels[levelIndex].level <= this.currentLevel;
    }

    private getSelectedLevelCollection(levelIndex: number){
        const ret = this._flatChoices
        .filter(x => x.level.level === this.questionItem.levels[levelIndex].level)
        .filter(x => x.parent_id === this.selections.get(levelIndex))
        ;
        return ret;
    }

    onSelectionChange(event: MatSelectChange): void {
        const id = event.value as string;
        const selection = this._flatChoices.find(x => x.id === id);
        this.currentLevel = selection.level.level+1;
        this.selections.set(this.currentLevel, selection.id);
        if (selection.level.level === this.questionItem.levels[this.questionItem.levels.length-1].level) {
            this.updated.emit(this.createUpdateEvent(selection));
        }
    }

    trackByItem(index: number, item: DrillDownSelectionFlat) {
        return item.description;
    }

    private createFormGroup(levels: DrillDownLevel[]): FormGroup[] {
        return levels.map(level => new FormGroup({id:  new FormControl('')}));
    }

    private createUpdateEvent(selected: DrillDownSelectionFlat)
    {
        // TODO: add support properties like required, etc
        return {
            id: this.questionItem.id,
            answer: {
                answer_type: "DrillDown",
                selected_id: selected.id,
                text: selected.description
            }
        }
    }
    
    
    private getSelectionsFlat(choices: any[], fromLvIndex: number = 0, parent: DrillDownSelectionFlat = null): DrillDownSelectionFlat[] {
        const ret: DrillDownSelectionFlat[] = [];
        const level = this.questionItem.levels[fromLvIndex];
        const prefix = parent !== null ? `${parent.id}-` :'';
        for (let i=0; i<choices.length; i++) {
            const flatterned = {id: `${prefix}${i}`, description: choices[i][level.name_property], level: level, parent_id: parent !== null ? parent.id : null};
            ret.push(flatterned);
            if (level.next_level_property) {
                ret.push(...this.getSelectionsFlat(choices[i][level.next_level_property], fromLvIndex + 1, flatterned));
            }
        }
        return ret;
    }
    
}
